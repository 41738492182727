import { Workbook } from "exceljs"
import * as fs from "file-saver"
import moment from "moment"
import IWinnerItem from "../interfaces/winnerItem"
import ICampaign from "../interfaces/campaign"
import ICustomBidderDetail from "../interfaces/customBidderDetail"
import IBid from "../interfaces/bid"
import useCommonFunctions from "./useCommonFunctions"
import IBidderDetail from "../interfaces/bidderDetail"
import { IUser } from "../interfaces/user"
import IOrganisation from "../interfaces/organisation"
import useOrganisationCommonFunctions from "./useOrganisationCommonFunctions"
import IBidWithData from "../interfaces/bidWithData"
import IGetWinnerResponse, { IWinnerBidAndLot } from "../interfaces/winningBidResponse"
import { IWinner } from "../interfaces/winner"
import { ICampaignUser } from "../interfaces/campaignUser"

const useExcel = () => {
	let commonFunctions = useCommonFunctions()
	const commonOrgnaisationFunctions = useOrganisationCommonFunctions()

	const generateWinnerReport = (campaign: ICampaign, winnerList: IWinner[], isPurchaseReport: boolean) => {
		const currencyFormatter = new Intl.NumberFormat("en-US", { style: "currency", currency: campaign.currency, minimumFractionDigits: 0, maximumFractionDigits: 2 })
		// var tableNumberDetailId;

		//Create workbook and worksheet
		let workbook = new Workbook()
		let worksheet = workbook.addWorksheet("Winner Report")

		//set the headers
		let headers = ["Name", "Email", "Phone", "Items Won", `Total Bid Value (${commonFunctions.getCurrencySymbol(campaign.currency)})`]
		if (campaign.paymentMethod === "PAYMENT-LINK") headers.push("Stripe Payment Link")
		campaign.customBidDetailList.forEach(customBidderDetail => {
			headers.push(customBidderDetail.label)
		})
		let headerRow = worksheet.addRow(headers)
		headerRow.font = { bold: true, color: { argb: "FFFFFFFF" } }

		//set the widths of the columns and fill the header row color
		headers.forEach((element, index) => {
			if (index + 1 === 4 || index + 1 === 6) {
				worksheet.getColumn(index + 1).width = 65
			} else {
				worksheet.getColumn(index + 1).width = 25
			}
			headerRow.getCell(index + 1).fill = { type: "pattern", pattern: "solid", fgColor: { argb: "FF1D3461" } }
		})

		winnerList.forEach(winner => {
			//generate a list of the won lots and cost.
			let winnerItems = ""
			let row: any[] = []
			let cbdValuesArray: string[] = []

			winner.winnerItems.forEach((winnerItem, winnerItemIndex) => {
				winnerItem.bidAndLotList.forEach((bidAndLot) => {
					winnerItems += `${bidAndLot.winningLot.title} `

					if (bidAndLot.winningBid.bidList[0].cardFeeCoveredPercentage > 0) {
						winnerItems += `(${currencyFormatter.format(bidAndLot.winningBid.totalValue)} + ${currencyFormatter.format(
							commonFunctions.calculateServiceFee(bidAndLot.winningBid.totalValue, bidAndLot.winningBid.bidList[0].cardFeeCoveredPercentage)
						)})`
					} else {
						winnerItems += `(${currencyFormatter.format(bidAndLot.winningBid.totalValue)})`
					}

					winnerItems += `[${bidAndLot.winningBid.paymentRefId ? `PAID - ${bidAndLot.winningBid.paymentRefId}` : "PAYMENT PENDING"}] `

					if (winnerItemIndex !== winner.winnerItems.length - 1) {
						winnerItems += ` \r\n`
					}
				})

			})

			row = [
				winner.campaignUser ? `${commonFunctions.capitalize(winner.campaignUser.firstName)} ${commonFunctions.capitalize(winner.campaignUser.surname)}` : commonFunctions.titleCase(winner.bidderName),
				winner.campaignUser ? winner.campaignUser.email : winner.bidderId,
				winner.campaignUser.phone,
				winnerItems,
				currencyFormatter.format(
					commonFunctions.calculateTotalWinningBidValue(winner, true)
				),
			]

			if (winner.paymentMethod === "PAYMENT-LINK" && winner.stripePaymentLink) {
				row.push(winner.stripePaymentLink.paymentLinkUrl)
			} else {
				row.push("")
			}

			winner.winnerItems.forEach((winnerItem, winnerItemIndex) => {
				campaign.customBidDetailList.forEach((campaignCustomBidderDetail, campaignIndex) => {
					winnerItem.bidAndLotList.forEach((bidAndLot) => {
						bidAndLot.winningBid.bidList.forEach((bid) => {
							bid.customBidDetailList &&
								bid.customBidDetailList.forEach((winnerItemCustomBidderDetail: ICustomBidderDetail, cbdIndex) => {
									if (campaignCustomBidderDetail.label === winnerItemCustomBidderDetail.label) {
										if (winnerItemIndex === winner.winnerItems.length - 1) {
											if (cbdValuesArray[campaignIndex]) {
												cbdValuesArray[campaignIndex] += `, ${winnerItemCustomBidderDetail.value}`
											} else {
												cbdValuesArray[campaignIndex] = `${winnerItemCustomBidderDetail.value}`
											}
										} else {
											if (cbdValuesArray[campaignIndex]) {
												cbdValuesArray[campaignIndex] += `, ${winnerItemCustomBidderDetail.value}\r\n`
											} else {
												cbdValuesArray[campaignIndex] = `${winnerItemCustomBidderDetail.value}\r\n`
											}
										}
									}
								})
						})
					})

				})
			})

			cbdValuesArray.forEach(element => {
				row.push(element)
			})

			let winnerRow = worksheet.addRow(row)
			winnerRow.alignment = { vertical: "middle", wrapText: true }
		})

		//Generate Excel File with given name
		workbook.xlsx.writeBuffer().then(data => {
			let blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
			fs.saveAs(blob, `${campaign.name} ${isPurchaseReport ? 'Purchaser' : 'Winner'} Report.xlsx`)
		})
	}

	const generateProfitLossReport = (campaign: ICampaign, winnerList: IWinner[]) => {
		// var tableNumberDetailId;

		//Create workbook and worksheet
		let workbook = new Workbook()
		let worksheet = workbook.addWorksheet("P&L Report")

		//set the headers
		let headers = ["Bidder Name", "Lot", "Bidder Email", "Bidder Phone", "Bid", "Supply", "Surplus", "Payment Status", "Fees Donated"]
		let headerRow = worksheet.addRow(headers)
		headerRow.font = { bold: true, color: { argb: "FFFFFFFF" }, name: "Arial" }

		// currency format
		const numFmtStr = `_("${commonFunctions.getCurrencySymbol(campaign.currency)}"* #,##0.00_);_("${commonFunctions.getCurrencySymbol(campaign.currency)}"* (#,##0.00);_("${commonFunctions.getCurrencySymbol(campaign.currency)}"* "-"??_);_(@_)`

		//set the widths of the columns and fill the header row color
		headers.forEach((element, index) => {
			if (index + 1 === 2) {
				worksheet.getColumn(index + 1).width = 65
			} else {
				worksheet.getColumn(index + 1).width = 22
			}
			headerRow.getCell(index + 1).fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ff26c0b9" } }
			headerRow.getCell(index + 1).border = { bottom: { style: "thin", color: { argb: "ff000000" } } }
		})

		let subTotal: number = 0
		let unpaidSurplus: number = 0
		let totalBidValue: number = 0

		winnerList.forEach(winner => {
			let row: any[] = []

			winner.winnerItems.forEach((winnerItem, winnerItemIndex) => {

				winnerItem.bidAndLotList.forEach((bidAndLot: IWinnerBidAndLot) => {
					row = [
						winner.campaignUser ? `${commonFunctions.capitalize(winner.campaignUser.firstName)} ${commonFunctions.capitalize(winner.campaignUser.surname)}` : commonFunctions.titleCase(winner.bidderName),
						bidAndLot.winningLot.title,
						winner.campaignUser ? winner.campaignUser.email : winner.bidderId,
						winner.campaignUser.phone,
						bidAndLot.winningBid.totalValue,
						bidAndLot.winningLot.costPrice,
						bidAndLot.winningBid.totalValue - (bidAndLot.winningLot.costPrice * bidAndLot.winningBid.totalCount),
						commonFunctions.determineLotPaidStatus(bidAndLot.winningBid.bidList[0]),
					]

					subTotal += bidAndLot.winningBid.totalValue - (bidAndLot.winningLot.costPrice * bidAndLot.winningBid.totalCount)
					totalBidValue += bidAndLot.winningBid.totalValue

					if (commonFunctions.determineLotPaidStatus(bidAndLot.winningBid.bidList[0]) === "PAID" ||
						commonFunctions.determineLotPaidStatus(bidAndLot.winningBid.bidList[0]) === "PAID - MANUAL") {
						row.push(commonFunctions.calculateServiceFee(bidAndLot.winningBid.totalValue, bidAndLot.winningBid.bidList[0].cardFeeCoveredPercentage))
					}

					let winnerRow = worksheet.addRow(row)
					winnerRow.alignment = { horizontal: "left", wrapText: true }
					winnerRow.font = { name: "Arial" }

					if (commonFunctions.determineLotPaidStatus(bidAndLot.winningBid.bidList[0]) !== "PAID" &&
					commonFunctions.determineLotPaidStatus(bidAndLot.winningBid.bidList[0]) !== "PAID - MANUAL") {
						headers.forEach((element, index) => {
							//fill all columns red apart from final
							if (index !== headers.length - 1) {
								winnerRow.getCell(index + 1).fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff4040" } }
							}
						})
						unpaidSurplus += bidAndLot.winningBid.totalValue - (bidAndLot.winningBid.totalCount * bidAndLot.winningLot.costPrice)
					}

					//fill the last column grey
					winnerRow.getCell(9).fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffc4c4c4" } }

					//format the cell as currency
					winnerRow.getCell(5).numFmt = numFmtStr
					winnerRow.getCell(6).numFmt = numFmtStr
					winnerRow.getCell(7).numFmt = numFmtStr
					winnerRow.getCell(9).numFmt = numFmtStr
				});

			})
		})

		let subTotalRow = worksheet.addRow([null, null, null, null, null, "Sub-total", subTotal, null, null])
		subTotalRow.alignment = { horizontal: "left", wrapText: true }
		subTotalRow.font = { name: "Arial" }
		subTotalRow.getCell(6).border = { top: { style: "thin", color: { argb: "ff000000" } } }
		subTotalRow.getCell(7).border = { top: { style: "thin", color: { argb: "ff000000" } } }
		subTotalRow.getCell(7).numFmt = numFmtStr

		let platformFeeRow = worksheet.addRow([
			null,
			null,
			null,
			null,
			null,
			"Card fee",
			Math.round(totalBidValue * -0.0199 * 100) / 100,
			null,
			null,
		])
		platformFeeRow.alignment = { horizontal: "left", wrapText: true }
		platformFeeRow.font = { name: "Arial" }
		platformFeeRow.getCell(7).numFmt = numFmtStr

		let grossSurplusRow = worksheet.addRow([
			null,
			null,
			null,
			null,
			null,
			"Gross surplus raised",
			subTotal - Math.round(totalBidValue * 0.0199 * 100) / 100,
			null,
			null,
		])
		grossSurplusRow.alignment = { horizontal: "left", wrapText: true }
		grossSurplusRow.getCell(6).border = { top: { style: "medium", color: { argb: "ff000000" } } }
		grossSurplusRow.getCell(6).font = { bold: true, name: "Arial" }
		grossSurplusRow.getCell(7).border = { top: { style: "medium", color: { argb: "ff000000" } } }
		grossSurplusRow.getCell(7).font = { bold: true, name: "Arial" }
		grossSurplusRow.getCell(7).numFmt = numFmtStr

		worksheet.addRow([])
		worksheet.addRow([])

		if (unpaidSurplus > 0) {
			let unpaidSurplusRow = worksheet.addRow([null, null, null, null, null, "Unpaid surplus", unpaidSurplus * -1, null, null])
			unpaidSurplusRow.alignment = { horizontal: "left", wrapText: true }
			unpaidSurplusRow.font = { name: "Arial" }
			unpaidSurplusRow.getCell(7).fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff4040" } }
			unpaidSurplusRow.getCell(7).numFmt = numFmtStr
			worksheet.addRow([])

			let totalTransferrableRow = worksheet.addRow([
				null,
				null,
				null,
				null,
				null,
				"Raised / transferable",
				subTotal - Math.round(totalBidValue * 0.0199 * 100) / 100 - unpaidSurplus,
				null,
				null,
			])
			totalTransferrableRow.alignment = { horizontal: "left", wrapText: true }
			totalTransferrableRow.font = { name: "Arial" }
			totalTransferrableRow.getCell(7).numFmt = numFmtStr
		}

		//Generate Excel File with given name
		workbook.xlsx.writeBuffer().then(data => {
			let blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
			fs.saveAs(blob, `${campaign.name} Profit Loss Report.xlsx`)
		})
	}

	const generateBidReport = (campaign: ICampaign, bidList: IBidWithData[]) => {
		//Create workbook and worksheet
		let workbook = new Workbook()
		let worksheet = workbook.addWorksheet("Bid Report")

		const currencyFormatter = new Intl.NumberFormat("en-US", { style: "currency", currency: campaign.currency, minimumFractionDigits: 0, maximumFractionDigits: 2 })


		//set the headers
		let headers = [
			"Timestamp",
			"Bidder Name",
			"Bidder Email",
			"Bidder Phone",
			"Lot",
			`Bid (${commonFunctions.getCurrencySymbol(campaign.currency)})`,
		]

		if (campaign.isCompetitiveMaxBidding) {
			headers.push("Maximum Bid")
		}

		headers.push("Gift Aid Address")    

		if (campaign.marketingOptInText){
			headers.push("Marketing Opt In")
		}

		headers.push("Status")
		headers.push("Error Message")

		campaign.customBidDetailList.forEach(customBidderDetail => {
			headers.push(customBidderDetail.label)
		})
		let headerRow = worksheet.addRow(headers)
		headerRow.font = { bold: true, color: { argb: "FFFFFFFF" } }

		//set the widths of the columns and fill the header row color
		headers.forEach((element, index) => {
			if (index + 1 === 5) {
				worksheet.getColumn(index + 1).width = 50
			} else {
				worksheet.getColumn(index + 1).width = 25
			}
			headerRow.getCell(index + 1).fill = { type: "pattern", pattern: "solid", fgColor: { argb: "FF1D3461" } }
		})

		bidList.forEach(bid => {
			let row: any[] = [
				moment(bid.createdAt).format("HH:mm:ss DD/MM/yyyy"),
				`${commonFunctions.capitalize(bid.campaignUser.firstName)} ${commonFunctions.capitalize(bid.campaignUser.surname)}`,
				bid.campaignUser.email,
				bid.campaignUser.phone,
				bid.count > 1
					? `${bid.lot.title} (${bid.count} x ${currencyFormatter}).format(bid.value)})`
					: bid.lot.title,
				bid.count > 1 ? bid.value * bid.count : bid.value,
			]

			if (campaign.isCompetitiveMaxBidding) {
				row.push(bid.maxValue)
			}

			row.push(bid.giftAidAddress ? bid.giftAidAddress : null)

			if (campaign.marketingOptInText){
				row.push(bid.isMarketingOptIn ? "Yes" : "No")
			}

			row.push(bid.status)
			row.push(bid.error ? bid.error.code : null)

			bid.customBidDetailList.forEach(element => {
				row.push(element.value)
			})

			worksheet.addRow(row)
		})

		//Generate Excel File with given name
		workbook.xlsx.writeBuffer().then(data => {
			let blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
			fs.saveAs(blob, `${campaign.name} Bid Report.xlsx`)
		})
	}
	const generateBidderReport = (biderList: IBidderDetail[]) => {
		//Create workbook and worksheet
		let workbook = new Workbook()
		let worksheet = workbook.addWorksheet("Bidder Report")

		//set the headers
		let headers = ["Bidder Name", "Bidder Email", "Bidder Phone"]
		let headerRow = worksheet.addRow(headers)
		headerRow.font = { bold: true, color: { argb: "FFFFFFFF" } }

		//set the widths of the columns and fill the header row color
		headers.forEach((element, index) => {
			worksheet.getColumn(index + 1).width = 25
			headerRow.getCell(index + 1).fill = { type: "pattern", pattern: "solid", fgColor: { argb: "FF1D3461" } }
		})

		biderList.forEach(bidder => {
			let row: any[] = [
				bidder.name ? bidder.name : `${commonFunctions.capitalize(bidder.campaignUser.firstName)} ${commonFunctions.capitalize(bidder.campaignUser.surname)}`,
				bidder.campaignUser ? bidder.campaignUser.email : bidder.bidderId,
				bidder.phone,
			]

			worksheet.addRow(row)
		})

		//Generate Excel File with given name
		workbook.xlsx.writeBuffer().then(data => {
			let blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
			fs.saveAs(blob, `Bidder Report.xlsx`)
		})
	}
	const generateUserReport = (campaignUserList: ICampaignUser[], bidList: IBidWithData[], campaignSlug: string, organisationId: string) => {
		const countBids = (campaignUser: ICampaignUser, status: "SUCCEEDED" | "FAILED/PENDING"): number => {
			let bidCount = 0
			// const stripeCustomerId = user.stripeConnectedAccountList.find(x => x.organisationId === organisationId)?.customerId

			if (status === "SUCCEEDED") {
				bidList.forEach(bid => {
					if (campaignUser._id === bid.campaignUser._id && bid.status === "SUCCEEDED") {
						bidCount++
					}
				})
			} else {
				bidList.forEach(bid => {
					if (campaignUser._id === bid.campaignUser._id && bid.status !== "SUCCEEDED") {
						bidCount++
					}
				})
			}

			return bidCount
		}

		//Create workbook and worksheet
		let workbook = new Workbook()
		let worksheet = workbook.addWorksheet("User Report")

		//set the headers
		let headers = ["Latest Login", "Name", "Email", "New Sign Up?", "Successful Bids", "Failed / Pending Bids "]
		let headerRow = worksheet.addRow(headers)
		headerRow.font = { bold: true, color: { argb: "FFFFFFFF" } }

		//set the widths of the columns and fill the header row color
		headers.forEach((element, index) => {
			// if (index + 1 === 6) {
			//     worksheet.getColumn(index + 1).width = 50;
			// } else {
			worksheet.getColumn(index + 1).width = 25
			// }
			headerRow.getCell(index + 1).fill = { type: "pattern", pattern: "solid", fgColor: { argb: "FF1D3461" } }
		})

		campaignUserList.forEach(campaignUser => {
			let row: any[] = [
				moment(campaignUser.latestLoginDateTime).format("HH:mm DD/MM/YYYY"),
				`${commonFunctions.capitalize(campaignUser.firstName)} ${commonFunctions.capitalize(campaignUser.surname)}`,
				campaignUser.email,
				String(campaignUser.signUpCampaignId === campaignSlug),
				countBids(campaignUser, "SUCCEEDED"),
				countBids(campaignUser, "FAILED/PENDING"),
			]

			worksheet.addRow(row)
		})

		//Generate Excel File with given name
		workbook.xlsx.writeBuffer().then(data => {
			let blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
			fs.saveAs(blob, `User Report.xlsx`)
		})
	}

	const generateLotReport = (campaign: ICampaign, winnerList: IGetWinnerResponse[]) => {
		//Create workbook and worksheet
		let workbook = new Workbook()
		let worksheet = workbook.addWorksheet("Lot Report")

		//set the headers
		let headers = [
			"Lot Number",
			"Lot",
			`Reserve (${commonFunctions.getCurrencySymbol(campaign.currency)})`,
			"Winning Bidder",
			"Bidder Email",
			"Bidder Phone",
			`Winning Bid (${commonFunctions.getCurrencySymbol(campaign.currency)})`,
		]

		if (campaign.isCompetitiveMaxBidding) {
			headers.push(`Maximum Bid (${commonFunctions.getCurrencySymbol(campaign.currency)})`)
		}

		headers.push(`Cost Price (${commonFunctions.getCurrencySymbol(campaign.currency)})`)
		headers.push(`Profit (${commonFunctions.getCurrencySymbol(campaign.currency)})`)

		let headerRow = worksheet.addRow(headers)
		headerRow.font = { bold: true, color: { argb: "FFFFFFFF" } }
		headerRow.alignment = { vertical: "middle", horizontal: "center", wrapText: true }

		//set the widths of the columns and fill the header row color
		headers.forEach((element, index) => {
			if (index + 1 === 1) {
				worksheet.getColumn(index + 1).width = 15
			} else if (index + 1 === 2) {
				worksheet.getColumn(index + 1).width = 50
			} else {
				worksheet.getColumn(index + 1).width = 25
			}
			headerRow.getCell(index + 1).fill = { type: "pattern", pattern: "solid", fgColor: { argb: "FF1D3461" } }
		})

		const getParentTitle = (parentLotId: string): string => {
			let title = ''
			winnerList.forEach((winner: IGetWinnerResponse) => {
				winner.bidAndLotList.forEach(bidAndLot => {
					if (bidAndLot.winningLot._id === parentLotId) {
						title = bidAndLot.winningLot.title
					}
				})
			})
			return title
		}

		winnerList.forEach(winnerItem => {
			winnerItem.bidAndLotList.forEach(bidAndLot => {

				if (bidAndLot.winningLot.type !== "PARENT") {
					let row = [
						bidAndLot.winningLot.orderNumber,
						bidAndLot.winningLot.parentLotId ? `${getParentTitle(bidAndLot.winningLot.parentLotId)} - ${bidAndLot.winningLot.title}` : bidAndLot.winningLot.title,
						bidAndLot.winningLot.reservePrice,
						winnerItem.winningUser.firstName ? `${commonFunctions.capitalize(winnerItem.winningUser.firstName)} ${commonFunctions.capitalize(winnerItem.winningUser.surname)} ` : '-',
						winnerItem.winningUser ? winnerItem.winningUser.email : bidAndLot.winningBid.userId,
						bidAndLot.winningBid.bidList ? bidAndLot.winningBid.bidList[0].campaignUser?.phone : '-',
						bidAndLot.winningBid.bidList ? bidAndLot.winningBid.totalValue : '-'
					]

					if (campaign.isCompetitiveMaxBidding && bidAndLot.winningBid.bidList) {
						row.push(bidAndLot.winningBid.bidList[0].maxValue)
					}

					row.push(bidAndLot.winningLot.costPrice)
					row.push(bidAndLot.winningBid.bidList && bidAndLot.winningBid.totalValue ? bidAndLot.winningBid.totalValue - (bidAndLot.winningLot.costPrice * bidAndLot.winningBid.totalCount) : '')

					let winnerRow = worksheet.addRow(row)

					winnerRow.alignment = { vertical: "middle", horizontal: "center", wrapText: true }
				}

			})
		})

		//Generate Excel File with given name
		workbook.xlsx.writeBuffer().then(data => {
			let blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
			fs.saveAs(blob, `${campaign.name}_lot_report.xlsx`)
		})
	}
	const generateOrganisationList = (organisationList: IOrganisation[], campaignList: ICampaign[]) => {
		//Create workbook and worksheet
		let workbook = new Workbook()
		let worksheet = workbook.addWorksheet("Organisation List")



		//set the headers
		let headers = [
			"Name",
			"Active Licences",
			"Total Licences Purchased",
			"Gross Funds Collected",
			"Total Fees Paid"
		]
		let headerRow = worksheet.addRow(headers)
		headerRow.font = { bold: true, color: { argb: "FFFFFFFF" } }
		headerRow.alignment = { vertical: "middle", horizontal: "center", wrapText: true }

		//set the widths of the columns and fill the header row color
		headers.forEach((element, index) => {
			// if (index + 1 === 1) {
			// 	worksheet.getColumn(index + 1).width = 15
			// } else if (index + 1 === 2) {
			// 	worksheet.getColumn(index + 1).width = 50
			// } else {
			// 	worksheet.getColumn(index + 1).width = 25
			// }
			worksheet.getColumn(index + 1).width = 25
			headerRow.getCell(index + 1).fill = { type: "pattern", pattern: "solid", fgColor: { argb: "FF1D3461" } }
		})

		organisationList.forEach(organisation => {
			let organisationRow = worksheet.addRow([
				organisation.name,
				commonOrgnaisationFunctions.determineActiveLicences(campaignList.filter(c => c.organisationId === organisation._id).length ?? 0, organisation),
				commonOrgnaisationFunctions.determineTotalLicences(organisation.licenceBundleList),
				new Intl.NumberFormat("en-US", {
					style: "currency",
					currency: "GBP",
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
				}).format(organisation.totalRevenue ?? 0),
				new Intl.NumberFormat("en-US", {
					style: "currency",
					currency: "GBP",
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
				}).format(commonOrgnaisationFunctions.determineTotalFees(organisation))
			])

			organisationRow.alignment = { vertical: "middle", horizontal: "center", wrapText: true }
		})

		//Generate Excel File with given name
		workbook.xlsx.writeBuffer().then(data => {
			let blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
			fs.saveAs(blob, `Going Gone Organisation Report.xlsx`)
		})
	}

	return {
		generateWinnerReport,
		generateProfitLossReport,
		generateBidReport,
		generateBidderReport,
		generateLotReport,
		generateUserReport,
		generateOrganisationList
	}
}
export default useExcel
