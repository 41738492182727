import React from 'react'
import './bigScreenCarousel.scss'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'
import { EffectCoverflow } from "swiper"
import { Navigation, Pagination, Scrollbar, A11y, Autoplay, Lazy } from 'swiper';
import BigScreenCard from './BigScreenHorizontalCard'
import { IBigScreenPayload } from '../../../interfaces/bigScreenPayload'
import { useParams } from "react-router-dom"
import { useEffect, useState } from 'react'
import useFetch from '../../../hooks/useFetch'
import { API_PATHS, CAMPAIGN_API_PATHS, getCampaignQueryString } from '../../../common/ApiPaths'
import ICampaign from '../../../interfaces/campaign';


interface InputProps {
    bigScreenPayload: IBigScreenPayload
}

const BigScreenCarouselHorizontal = (props: InputProps) => {

    const [campaign, setCampaign] = useState<ICampaign>({} as ICampaign)


    const campaignsApi = useFetch(API_PATHS.CAMPAIGNS);
    let { campaignSlug } = useParams()

    const getCampaign = async () => {
        try {
            if (campaignSlug !== 'new') {
                let data: ICampaign = await campaignsApi.get(`${CAMPAIGN_API_PATHS.GET_CAMPAIGN}${getCampaignQueryString(campaignSlug)}`)
                setCampaign(data)
            }
        }
        catch (err: any) {
            console.error(err)
        }
    }

    useEffect(() => {
        getCampaign()
    }, [campaignSlug])


    return (
        <div className='big-screen-carousel horizontal'>
            
            <Swiper
                id="main"
                modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay, Lazy, EffectCoverflow]}
                initialSlide={0}
                slidesPerGroup={3}
                slidesPerView={3}
                autoHeight={true}
                loop={true}
                autoplay={{delay: (props.bigScreenPayload.lotScrollSpeed * 1000)} }
            >
            {props.bigScreenPayload.lotList.map((lot, index) => {
                    return (
                        <SwiperSlide key={index}>
                            <BigScreenCard lot={lot} bigScreenPayload={props.bigScreenPayload} campaign={campaign} />
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        </div>
    )
}

export default BigScreenCarouselHorizontal