import './lotConfigSection.scss'
import React from 'react'
import { FormControl, FormHelperText, InputLabel, MenuItem, Paper, Select, TextField } from '@mui/material'
import ILot from '../../../../interfaces/lot'
import IValidationObject from '../../../../interfaces/validationObject'
import CustomPageSelect from '../../../shared/customPageSelect/CustomPageSelect'
import { LotType } from '../../../../common/enums/LotType'


interface InputProps {
    lot: ILot
    setLot: (lot: ILot) => void
    isNewLot: boolean
    campaignLotList: ILot[]
    orderNumberSetting: string
    setOrderNumberSetting: (setting: string) => void
    isChildLot: boolean
    validationObject: IValidationObject[]
    isCampaignClosed: boolean
    fromVMS: boolean
}

const LotConfigSection = (props: InputProps) => {

    const handleBlur = (event) => {
        const { name, value } = event.target
        props.setLot({ ...props.lot, [name]: value })
    }

    const handleOrderNumberSettingChange = (event) => {
        const { value } = event.target
        props.setOrderNumberSetting(value)
    }

    const handleCustomPageChange = (event) => {
        if (event.target.value === "lot-page") {
            props.setLot({ ...props.lot, customPageId: undefined })
        } else {
            props.setLot({ ...props.lot, customPageId: event.target.value })
        }
    }

    const getValidation = (name): IValidationObject => {
        let returnValue: IValidationObject = {} as IValidationObject
        props.validationObject.forEach(element => {
            if (name === element.name) {
                returnValue = element
            }
        });
        return returnValue
    }

    return (
        <div className="lot-config-section">
            <h2 className="subtitle">Lot Configuration.</h2>
            <span className="description">Set the key information about the lot.</span>
            <Paper className="input-wrapper">
                <div className="field-wrapper">
                    <TextField
                        id="title"
                        name="title"
                        label="Lot Title"
                        variant="outlined"
                        defaultValue={props.lot.title}
                        onBlur={handleBlur}
                        required
                        error={!getValidation("title").isValid}
                        helperText={getValidation("title").isValid ? "The main title / name of the lot." : getValidation("title").validationMessage}
                        disabled={props.isCampaignClosed || props.fromVMS}
                    />
                </div>
                <div className="field-wrapper">
                    <TextField
                        id="subtitle"
                        name="subtitle"
                        label="Lot Subtitle"
                        variant="outlined"
                        defaultValue={props.lot.subtitle}
                        onBlur={handleBlur}
                        helperText="More detailed information about the lot, displayed beneath the title."
                        disabled={props.isCampaignClosed}
                    />
                </div>
                <div className="field-wrapper">
                    <FormControl
                        variant="outlined"
                        required
                        error={!getValidation("type").isValid}
                    >
                        <InputLabel id="lot-type-label">Lot Type</InputLabel>
                        <Select
                            labelId="lot-type-label"
                            id="lotType"
                            name="type"
                            defaultValue={props.lot.type}
                            onChange={handleBlur}
                            label="Lot Type *"
                            disabled={props.isCampaignClosed}
                        >
                            <MenuItem key={1} value={"COMPETITIVE"}>Competitive</MenuItem>
                            <MenuItem key={3} value={"SEALED"}>Sealed</MenuItem>
                            <MenuItem key={4} value={"BUYITNOW"}>Buy it Now</MenuItem>
                            <MenuItem key={5} value={"VIEWONLY"}>View Only</MenuItem>
                            {!props.isChildLot && <MenuItem key={5} value={"PARENT"}>Parent</MenuItem>}
                        </Select>
                        {!getValidation("type").isValid && <FormHelperText>{getValidation("type").validationMessage}</FormHelperText>}
                    </FormControl>
                </div>

                <div className="field-wrapper">
                    <TextField
                        id="numberOfPeople"
                        name="numberOfPeople"
                        label="Number of People"
                        variant="outlined"
                        defaultValue={props.lot.numberOfPeople}
                        onBlur={handleBlur}
                        required
                        error={!getValidation("title").isValid}
                        helperText="How many people is the item for? Enter 0, to hide this field from the lot cards."
                        disabled={props.isCampaignClosed || props.fromVMS}
                    />
                </div>

                <div className="field-wrapper">
                    <FormControl variant="outlined" required>
                        <InputLabel id="order-number-setting-label">Lot Number Setting</InputLabel>
                        <Select
                            labelId="order-number-setting-label"
                            id="orderNumberSetting"
                            name="orderNumberSetting"
                            value={props.orderNumberSetting}
                            onChange={handleOrderNumberSettingChange}
                            label="Lot Number Setting *"
                            disabled={props.isCampaignClosed}
                        >
                            <MenuItem value={'NEW'}>New</MenuItem>
                            {!props.isNewLot && <MenuItem value={'EXISTING'}>Existing</MenuItem>}
                            {props.isNewLot && <MenuItem value={'END'}>Add to End</MenuItem>}
                        </Select>
                    </FormControl>
                </div>

                {props.orderNumberSetting !== "END" &&
                    <div className="field-wrapper">
                        <TextField
                            id="orderNumber"
                            name="orderNumber"
                            label="Lot Number"
                            variant="outlined"
                            defaultValue={props.lot.orderNumber}
                            onBlur={handleBlur}
                            required
                            disabled={props.orderNumberSetting === "EXISTING" ? true : props.isCampaignClosed}
                            error={!getValidation("orderNumber").isValid}
                            helperText={getValidation("orderNumber").isValid ? "The order the lot will be displayed on the lots page." : getValidation("orderNumber").validationMessage}
                        />
                    </div>
                }

                <div className="field-wrapper">
                    <FormControl variant="outlined">
                        <InputLabel id="isHidden-label">Lot Visibility</InputLabel>
                        <Select
                            labelId="isHidden-label"
                            id="isHidden"
                            name="isHidden"
                            value={props.lot.isHidden}
                            onChange={(event) => {
                                const { value } = event.target
                                if (value === "true") {
                                    props.setLot({ ...props.lot, isHidden: true })
                                } else {
                                    props.setLot({ ...props.lot, isHidden: false })
                                }
                            }}
                            label="Lot Visibility"
                            disabled={props.isCampaignClosed}
                        >
                            <MenuItem value={'false'}>Visible</MenuItem>
                            <MenuItem value={'true'}>Hidden</MenuItem>
                        </Select>
                        <FormHelperText>Should the lot be hidden from the lot list page?</FormHelperText>
                    </FormControl>
                </div>
                {!props.isChildLot &&
                    <div className='field-wrapper'>
                        <CustomPageSelect
                            handleChange={handleCustomPageChange}
                            existingCustomPageId={props.lot.customPageId}
                            isNewLot={false}
                        />
                    </div>
                }
                {props.lot.type !== LotType.PARENT &&
                    <div className="field-wrapper">
                        <FormControl variant="outlined">
                            <InputLabel id="isHidden-label">Rotate Lot Card Image?</InputLabel>
                            <Select
                                labelId="isHidden-label"
                                id="isRotatingLotCardImage"
                                name="isRotatingLotCardImage"
                                value={props.lot.isRotatingLotCardImage}
                                onChange={(event) => {
                                    const { value } = event.target
                                    if (value === "true") {
                                        props.setLot({ ...props.lot, isRotatingLotCardImage: true })
                                    } else {
                                        props.setLot({ ...props.lot, isRotatingLotCardImage: false })
                                    }
                                }}
                                label="Rotate Lot Card Image?"
                                disabled={props.isCampaignClosed}
                            >
                                <MenuItem value={'true'}>True</MenuItem>
                                <MenuItem value={'false'}>False</MenuItem>
                            </Select>
                            <FormHelperText>The lot card automatically scrolls through it's images.</FormHelperText>
                        </FormControl>
                    </div>
                }
            </Paper>
        </div>
    )
}

export default LotConfigSection