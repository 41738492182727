// TODO: Separate the api paths and functions as per modules
export const API_PATHS = {
	USERS: "users",
	CAMPAIGNS: "campaigns",
	LOTS: "lots",
	BIDS: "bids",
	EMAILS: "emails",
	SMS: "sms",
	COMMUNICATIONS: "communications",
	COMMUNICATIONLOGS: "communication-logs",
	BIGSCREENS: "big-screens",
	STRIPE: "stripe",
	AUDITLOG: "audit-log",
	ORGANISATIONS: "organisations",
	CATALOGUE: "catalogue",
	VOUCHER: "voucher",
};

export const USER_API_PATHS = {
	GET_USERS_LIST: "/list",
	GET_PAGINATED_USERS: "/paginated",
	GET_BY_SEARCH_STRING: "/list/getBySearchString/{searchString}",
	GET_USER: "",
	GET_OR_CREATE_STRIPE_USER: "/getOrCreateStripeUserByAuth0Id/{auth0Id}",
	CREATE_USER: "",
	UPDATE_USER: "/{userId}",
	UPDATE_USER_PASSWORD: "/updatePassword/{userId}",
	UPDATE_USER_PIN: "/updatePIN/{userId}",
	VALIDATE_USER_PIN: "/validatePIN/{userId}",
	GET_USERS_BY_USER_IDS: "/users/{userIds}",
	GET_USERS_BY_CAMPAIGN_ID: "/users/campaigns/{campaignId}/allUsers",
	UPDATE_USER_WITH_LATEST_LOGIN: "/updateUserWithLatestLogin/{auth0Id}",
};

export const getFilteredUserListQueryString = (
	campaignId?: string,
	organisationId?: string
): string => {
	let query = "";
	if (campaignId) {
		query += `campaignId=${campaignId}`;
	}
	if (organisationId) {
		query += `organisationId=${organisationId}`;
	}
	return `?${query}`;
};

export const getUserQueryString = (
	userId?: string,
	auth0Id?: string,
	stripeCustomerId?: string
): string => {
	let query = "";
	if (userId) {
		query += `userId=${userId}`;
	}
	if (auth0Id) {
		query += `${query.length ? "&" : ""}auth0Id=${auth0Id}`;
	}
	if (stripeCustomerId) {
		query += `${query.length ? "&" : ""}stripeCustomerId=${stripeCustomerId}`;
	}
	return `?${query}`;
};

export const CAMPAIGN_API_PATHS = {
	GET_PAGINATED_CAMPAIGNS: "/paginated",
	GET_VALID_CAMPAIGNS: "/list",
	GET_BY_SEARCH_STRING: "/list/getBySearchString/{searchString}",
	GET_CAMPAIGN: "",
	CREATE_CAMPAIGN: "",
	UPDATE_CAMPAIGN: "/{campaignId}",
	UPDATE_CAMPAIGNS_WITH_BROCHURE_INFO: "/updateCampaignsWithBrochureInfo",
	DELETE_CAMPAIGN: "/{campaignId}",
	GET_TAGS_FOR_CAMPAIGN: "/{campaignId}/tags",
	FIND_CAMPAIGNS_WITH_BIDS_BY_USERID: "/findWithBidsByUserId/{userId}",
	FIND_CAMPAIGNS_BY_USERID: "/user/{userId}",
};

export const ORGANISATIONS_API_PATHS = {
	GET_ORGANISATION: "",
	GET_ORGANISATION_LIST: "/getOrganisationList",
	CREATE_ORGANISATION: "",
	DELETE_ORGANISATION: "/{organisationId}",
	UPDATE_ORGANISATION: "/{organisationId}",
	ADD_PENDING_ORGANISATION: "/{organisationId}/addPendingLicenceBundle",
	DELETE_LICENCE_BUNDLE:
		"/{organisationId}/deleteLicenceBundle/{licenceBundleId}",
	GET_ORGANISATION_SUMMARY: "/{organisationId}/summary",
	REMOVE_STAFF: "/users/{userId}",
	GET_USERS_FOR_ORGANISATION_CAMPAIGNS:
		"/{organisationId}/campaigns/users/{searchString}",
};

export const getPaginatedCampaignsQueryString = (
	userId?: string,
	userRole?: string,
	limit?: number,
	pageNo?: number,
	organisationId?: string
): string => {
	let query = "";
	if (userId) {
		query += `userId=${userId}`;
	}
	if (userId) {
		query += `${query.length ? "&" : ""}userRole=${userRole}`;
	}
	if (limit) {
		query += `${query.length ? "&" : ""}limit=${limit}`;
	}
	if (pageNo) {
		query += `${query.length ? "&" : ""}pageNo=${pageNo}`;
	}
	if (organisationId) {
		query += `${query.length ? "&" : ""}organisationId=${organisationId}`;
	}
	return `?${query}`;
};

export const getValidCampaignsQueryString = (
	userId?: string,
	organisationId?: string,
	getOpenOnly?: string
): string => {
	let query = "";
	if (userId) {
		query += `userId=${userId}`;
	}
	if (organisationId) {
		query += `${query.length ? "&" : ""}organisationId=${organisationId}`;
	}
	if (getOpenOnly) {
		query += `${query.length ? "&" : ""}getOpenOnly=${getOpenOnly}`;
	}
	return `?${query}`;
};

export const getCampaignQueryString = (
	campaignSlug?: string,
	campaignId?: string
): string => {
	let query = "";
	if (campaignSlug) {
		query += `campaignSlug=${campaignSlug}`;
	}
	if (campaignId) {
		query += `campaignId=${campaignId}`;
	}
	return `?${query}`;
};

export const getCampaignsBySearchStringQueryString = (
	userId?: string,
	userRole?: string,
	getOpenOnly: boolean = false,
	getDeleted: boolean = false,
	organisationId?: string
): string => {
	let query = "";
	if (userId) {
		query += `${query.length ? "&" : ""}userId=${userId}`;
	}
	if (userRole) {
		query += `${query.length ? "&" : ""}userRole=${userRole}`;
	}
	if (getOpenOnly) {
		query += `${query.length ? "&" : ""}getOpenOnly=${getOpenOnly}`;
	}
	if (getDeleted) {
		query += `${query.length ? "&" : ""}getDeleted=${getDeleted}`;
	}
	if (organisationId) {
		query += `${query.length ? "&" : ""}organisationId=${organisationId}`;
	}
	return `?${query}`;
};

export const LOT_API_PATHS = {
	GET_LOTS: "/campaigns/{campaignId}/list",
	GET_LOT_BY_ID: "",
	CREATE_LOT: "",
	UPDATE_LOT_ORDER: "/updateLotOrder/{operation}",
	UPDATE_LOT_ORDER_SEQUENTIALLY: "/updateLotOrderNumbersSequentially",
	UPDATE_LOT: "",
	DELETE_LOT: "",
	COPY_LOTS_TO_CAMPAIGN: "/copyLotsToCampaign/{campaignId}",
	GET_CHILD_LOTS_LIST: "/getChildLotsList/{lotId}",
	DUPLICATE_LOTS: "/duplicate",
};

export const getFilteredLotsQueryString = (
	tag?: string,
	getHidden: boolean = true
): string => {
	let query = "";
	if (tag) {
		query += `tag=${tag}`;
	}
	if (getHidden !== undefined) {
		query += `${query.length ? "&" : ""}getHidden=${getHidden}`;
	}
	return `?${query}`;
};


export const getLotQueryString = (lotId?: string, lotSlug?: string): string => {
	let query = "";
	if (lotId) {
		query += `lotId=${lotId}`;
	}
	if (lotSlug) {
		query += `lotSlug=${lotSlug}`;
	}
	return `?${query}`;
};

export const BID_API_PATHS = {
	CREATE_BID: "",
	GET_BID: "/{bidId}",
	GET_BIDS: "/list",
	GET_PAGINATED_BIDS: "/paginated",
	DELETE_BID: "",
	UPDATE_BID: "",
	GET_BIDS_WITH_USER_AND_LOT: "/users/lots",
	GET_WINNING_BIDS: "/campaign/{campaignId}/winning",
	UPDATE_PENDING_BID_STATUS: "/updatePendingBidStatus/{pendingBidId}",
	GET_WINNING_AND_LOSING_BIDDERS_BY_CAMPAIGNID: "/bidding/users/{campaignId}/{category}",
}

export const getFilteredBidsQueryString = (
	lotId?: string,
	campaignId?: string,
	userId?: string
): string => {
	let query = "";
	if (campaignId) {
		query += `campaignId=${campaignId}`;
	}
	if (lotId) {
		query += `${query.length ? "&" : ""}lotId=${lotId}`;
	}
	if (userId) {
		query += `${query.length ? "&" : ""}userId=${userId}`;
	}
	return `?${query}`;
};

export const getPaginatedBidsQueryString = (
	campaignId: string,
	limit: number,
	pageNo: number,	
	status?: string
): string => {
	let query = `campaignId=${campaignId}&limit=${limit}&pageNo=${pageNo}`
	
	if (status) {
		query += `&status=${status}`;
	}
	
	return `?${query}`;
};

export const getFilteredBidWithDataQueryString = (
	campaignId?: string,
	startTime?: Date,
	lotId?: string
): string => {
	let query = "";
	if (campaignId) {
		query += `campaignId=${campaignId}`;
	}
	if (startTime) {
		query += `${query.length ? "&" : ""}startTime=${startTime}`;
	}
	if (lotId) {
		query += `${query.length ? "&" : ""}lotId=${lotId}`;
	}
	return `?${query}`;
};

export const STRIPE_API_PATHS = {
	CHARGE_SAVED_CARDS: "/chargeSavedCards",
	LIST_CUSTOMER_CARDS: "/{stripeConnectedAccountId}/{customerId}/cards",
	CREATE_SETUP_INTENT:
		"/{stripeConnectedAccountId}/organisations/{organisationId}/users/{userId}/createSetupIntent",
	CREATE_MULTIPLE_PAYMENT_LINKS: "/paymentLinks",
	CREATE_PAYMENT_LINK: "/paymentLink",
	DELETE_CARD: "/{stripeConnectedAccountId}/deleteCard/{paymentMethodId}",
	UPDATE_PAYMENT_LINK: "/paymentLink",
	CREATE_CHECKOUT_SESSION: "/createCheckoutSession",
};

export const AUDIT_LOG_API_PATHS = {
	GET_AUDIT_LOG_LIST: "",
	GET_PAGINATED_AUDIT_LOG_LIST: "/paginated",
	CREATE_AUDIT_LOG: "",
};

export const getFilteredAuditLogQueryString = (
	organisationId?: string,
	category?: string,
	campaignId?: string,
	userIds?: string[]
): string => {
	let query = "";
	if (organisationId) {
		query += `organisationId=${organisationId}`;
	}
	if (category) {
		query += `category=${category}`;
	}
	if (campaignId) {
		query += `${query.length ? "&" : ""}campaignId=${campaignId}`;
	}
	if (userIds) {
		query += `${query.length ? "&" : ""}userIds=${userIds.join(',')}`;
	}
	return `?${query}`;
};

export const getPaginatedAuditLogQueryString = (
	limit?: number,
	pageNo?: number,
	organisationId?: string,
	category?: string,
	campaignId?: string,
	userId?: string,
	fromDate?: Date,
	toDate?: Date,
	currentUserId?: string
): string => {
	let query = "";
	if (limit) {
		query += `${query.length ? "&" : ""}limit=${limit}`;
	}
	if (pageNo) {
		query += `${query.length ? "&" : ""}pageNo=${pageNo}`;
	}
	if (organisationId) {
		query += `${query.length ? "&" : ""}organisationId=${organisationId}`;
	}
	if (category) {
		query += `${query.length ? "&" : ""}category=${category}`;
	}
	if (campaignId) {
		query += `${query.length ? "&" : ""}campaignId=${campaignId}`;
	}
	if (userId) {
		query += `${query.length ? "&" : ""}userId=${userId}`;
	}
	if (fromDate) {
		query += `${query.length ? "&" : ""}fromDate=${fromDate.toISOString()}`;
	}
	if (toDate) {
		query += `${query.length ? "&" : ""}toDate=${toDate.toISOString()}`;
	}
	if (currentUserId) {
		query += `${query.length ? "&" : ""}currentUserId=${currentUserId}`;
	}
	return `?${query}`;
};

export const CATALOGUE_API_PATHS = {
	CREATE_CATALOGUE_ITEM: "",
	DELETE_ITEMS: "",
	GET_CATALOGUE_ITEMS: "/organisations/{organisationId}/list",
	TRANSFER_CATALOGUE_ITEMS_TO_CAMPAIGN: "/campaigns/{campaignId}",
	UPDATE_CATALOGUE_ITEM: "",
};

export const VOUCHER_API_PATHS = {
	CREATE_VOUCHER: "",
};

export const COMMUNICATIONS_API_PATHS = {
	SEND_SMS: "/recipients/sms",
	SEND_EMAIL: "/recipients/email",
	SEND_TEST_SMS: "/test/sms",
	SEND_TEST_EMAIL: "/test/email",
	GET_COMMUNICATIONS: "/campaign/{campaignId}/history",
};

export const COMMUNICATION_LOGS_API_PATHS = {
	GET_COMMUNICATION_LOGS: "/{communicationId}/logs",
};

export const getPaginatedCommunicationHistoryQueryString = (
	pageNo?: number,
	limit?: number
): string => {
	let query = "";
	if (limit) {
		query += `${query.length ? "&" : ""}limit=${limit}`;
	}
	if (pageNo) {
		query += `${query.length ? "&" : ""}pageNo=${pageNo}`;
	}
	return `?${query}`;
};
