import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Dialog, DialogActions, DialogContent, Tab } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import ICampaign from '../../interfaces/campaign'
import IBid from '../../interfaces/bid'
import './bidDetailsDialog.scss'
import ILot from '../../interfaces/lot'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import moment from 'moment'
import ICustomBidderDetail from '../../interfaces/customBidderDetail'
import { BidStatus } from '../../common/enums/BidStatus'
import { LotType } from '../../common/enums/LotType'
import useCommonFunctions from '../../hooks/useCommonFunctions'
import DeviceIcon from '../admin/shared/DeviceIcon'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'


interface InputProps {
    isDialogOpen: boolean
    handleClose: () => void
    campaign: ICampaign
    bid: IBid
    lot: ILot
}

const BidDetailsDialog = (props: InputProps) => {
    const [tabValue, setTabValue] = useState('1')
    const isCancelled = useRef(false)
    const commonFunctions = useCommonFunctions()

    useEffect(() => {
        return () => {
            isCancelled.current = true
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Dialog
            open={props.isDialogOpen}
            onClose={props.handleClose}
            aria-labelledby="form-dialog-title"
            className="bid-details-dialog"
        >
            <DialogContent>
                <div className='coloured-section' style={{ backgroundColor: props.campaign.menuBarBackgroundColor, color: props.campaign.menuBarFontColor }}>

                    <h2 className='title'>{`${props.lot.type === "BUYITNOW" ? 'Purchase' : 'Bid'} Details`}</h2>
                    <div className="bid-details-wrapper" >
                        <div className='detail' >
                            <div className='label'>Campaign: </div>
                            <div className='value'>{props.campaign.name}</div>
                        </div>
                        <div className='detail' >
                            <div className='label'>{props.lot.type === LotType.BUYITNOW ? 'Item ' : 'Lot '} Title: </div>
                            <div className='value'>{props.lot.title}</div>
                        </div>
                        {props.lot.type !== "BUYITNOW" &&
                            <div className='detail' >
                                <div className='label'>Lot Type: </div>
                                <div className='value'>{props.lot.type}</div>
                            </div>
                        }
                        {props.lot.type === "BUYITNOW" &&
                            <div className='detail'>
                                <div className='label'>Purchase Price: </div>
                                <div className='value'>
                                    <b>{commonFunctions.getDisplayedBid(props.bid, props.campaign.currency)}</b>
                                    {/* <b>{`${new Intl.NumberFormat('en-US', { style: 'currency', currency: props.campaign.currency ?? 'GBP', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(props.bid.value)} x ${props.bid.count} = ${new Intl.NumberFormat('en-US', { style: 'currency', currency: props.campaign.currency ?? 'GBP', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(props.bid.value * props.bid.count)}`}</b> */}
                                </div>
                            </div>
                        }
                        {(props.lot.type === "SEALED" || props.lot.type === "COMPETITIVE") &&
                            <div className='detail'>
                                <div className='label'>Bid Value: </div>
                                <div className='value'> <b>{commonFunctions.getDisplayedBid(props.bid, props.campaign.currency)}</b></div>
                                {/* <div className='value'> <b>{`${new Intl.NumberFormat('en-US', { style: 'currency', currency: props.campaign.currency ?? 'GBP', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(props.bid.value)}`}</b></div> */}
                            </div>
                        }
                        <div className='detail' >
                            <div className='label'>Timestamp: </div>
                            <div className='value'>{moment(props.bid.createdAt).format("HH:mm:ss DD/MM/YYYY")}</div>
                        </div>
                        {props.bid.device &&
                            <div className='detail' >
                                <div className='label'>Device: </div>
                                <div className='value'><DeviceIcon device={props.bid.device} /></div>
                            </div>
                        }

                    </div>
                </div>
                <div className='content-wrapper'>
                    <TabContext value={tabValue}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={(e, val) => setTabValue(val)} aria-label="lab API tabs example">
                                <Tab label={`${props.lot.type === "BUYITNOW" ? 'Purchase' : 'Bid'}`} value="1" />
                                <Tab label="User" value="2" />
                                {props.bid.customBidDetailList && props.bid.customBidDetailList.length > 0 && (
                                    <Tab label="Custom Bid Details" value="3" />
                                )}
                                {props.bid.termsAndConditionsAgreedInfo && (
                                    <Tab style={{ textTransform: 'unset' }} label="Ts&Cs" value="4" />
                                )}
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <div className="bid-details-wrapper" >

                                <div className='detail'>
                                    <div className='label'>Service Fee Covered: </div>
                                    <div className='value'> {props.bid.cardFeeCoveredPercentage ? props.bid.cardFeeCoveredPercentage : 0}%</div>
                                </div>

                                {props.lot.type !== "BUYITNOW" && (
                                    <>
                                        <div className='detail'>
                                            <div className='label'>Bid Type: </div>
                                            <div className='value'> {props.bid.paymentMethod === "PRE-AUTH" ? "Pre-Authenticated" : "Payment Link"}</div>
                                        </div>
                                        {props.bid.isAuto ?
                                            <div className='detail'>
                                                <div className='label'>Bid Creation: </div>
                                                <div className='value'> Automatic Bid</div>
                                            </div> : null
                                        }
                                        <div className='detail'>
                                            <div className='label'>Bid Status: </div>
                                            <div className='value'>
                                                {Object.keys(BidStatus).includes(props.bid.status) ?
                                                    <>
                                                        {props.bid.status === "FAILED" && <span className='failed'>Failed</span>}
                                                        {props.bid.status === "PENDING" && <span className='pending'>Pending</span>}
                                                        {props.bid.status === "SUCCEEDED" && <span className='succeeded'>Succeeded</span>}
                                                    </>
                                                    :
                                                    props.bid.status
                                                }

                                            </div>
                                        </div>

                                    </>
                                )}
                                {typeof (props.bid.isMarketingOptIn) !== "undefined" ?
                                    <div className='detail'>
                                        <div className='label'>Marketing Opt In: </div>
                                        <div className='value'> {String(props.bid.isMarketingOptIn)}</div>
                                    </div> : null
                                }
                                {props.bid.status === "FAILED" &&
                                    <div className='detail'>
                                        <div className='label'>Error: </div>
                                        <div className='value'>
                                            {props.bid.error.code}
                                        </div>
                                    </div>
                                }
                                {props.bid.paymentMethod === "PRE-AUTH" && props.bid.stripePayment && props.bid.stripePayment!.card && props.bid.stripePayment!.card.type &&
                                    <div className='detail'>
                                        <div className='label'>Card: </div>
                                        <div className='value'> {`${props.bid.stripePayment!.card.type.toUpperCase()} (xxxx xxxx xxxx ${props.bid.stripePayment!.card.last4digits})`}</div>
                                    </div>
                                }
                                {props.bid.stripePayment && props.bid.stripePayment!.paymentRefId &&
                                    <div className='detail'>
                                        <div className='label'>Payment Status: </div>
                                        <div className='value'>
                                            <div className="payment-status-wrapper">
                                                {commonFunctions.determineLotPaidStatusIcon(props.bid)}
                                            </div>
                                        </div>
                                    </div>

                                }
                                {props.bid.stripePayment && props.bid.stripePayment!.paymentRefId &&
                                    <div className='detail'>
                                        <div className='label'>Payment Reference: </div>
                                        <div className='value'> {props.bid.stripePayment!.paymentRefId}</div>
                                    </div>
                                }
                                {props.bid.stripePayment && props.bid.stripePayment?.receiptUrl &&
                                    <div className='detail'>
                                        <div className='label'>Receipt: </div>
                                        <div className='value'>
                                            <a target="_blank" className='hyperlink' href={props.bid.stripePayment?.receiptUrl} rel="noreferrer">View Receipt</a>
                                        </div>
                                    </div>
                                }
                            </div>
                        </TabPanel>
                        <TabPanel value="2">
                            <div className="bid-details-wrapper" >
                                <div className='detail' >
                                    <div className='label'>Bidder Name: </div>
                                    <div className='value'>{`${commonFunctions.capitalize(props.bid.campaignUser?.firstName ?? '')} ${commonFunctions.capitalize(props.bid.campaignUser?.surname ?? '')}`}</div>
                                </div>
                                <div className='detail' >
                                    <div className='label'>Email: </div>
                                    <div className='value'>{props.bid.campaignUser?.email}</div>
                                </div>
                                <div className='detail' >
                                    <div className='label'>Contact Number: </div>
                                    <div className='value'>{props.bid.campaignUser?.phone}</div>
                                </div>

                            </div>
                        </TabPanel>
                        {props.bid.customBidDetailList && props.bid.customBidDetailList.length > 0 && (
                            <TabPanel value="3">
                                <div className="bid-details-wrapper" >
                                    {props.bid.customBidDetailList.map((customBidderDetail: ICustomBidderDetail, index) => {
                                        return (
                                            <div className='detail' key={index} >
                                                <div className='label'>{customBidderDetail.label}: </div>
                                                <div className='value'>{customBidderDetail.value}</div>
                                            </div>
                                        )
                                    })}

                                </div>
                            </TabPanel>
                        )}
                        {props.bid.termsAndConditionsAgreedInfo && (
                            <TabPanel value="4">
                                <div className="bid-details-wrapper" >
                                    <div className='detail' >
                                        <div className='label'>Device: </div>
                                        <div className='value'>{props.bid.termsAndConditionsAgreedInfo.device}</div>
                                    </div>
                                    <div className='detail' >
                                        <div className='label'>IP Address: </div>
                                        <div className='value'>{props.bid.termsAndConditionsAgreedInfo.ipAddress}</div>
                                    </div>
                                    <div className='detail' >
                                        <div className='label'>Location: </div>
                                        <div className='value'>{props.bid.termsAndConditionsAgreedInfo.location}</div>
                                    </div>
                                    <div className='detail' >
                                        <div className='label'>TimeStamp: </div>
                                        <div className='value'>{props.bid.termsAndConditionsAgreedInfo.timestamp}</div>
                                    </div>
                                    <div className='detail' >
                                        <Accordion className='terms-and-conditions'>
                                            <AccordionSummary className='label' expandIcon={<ExpandMoreIcon />}>
                                                Terms & Conditions
                                            </AccordionSummary>
                                            <AccordionDetails className='value'>
                                                <div dangerouslySetInnerHTML={{ __html: props.bid.termsAndConditionsAgreedInfo.termsAndConditions }}></div>
                                            </AccordionDetails>
                                        </Accordion>
                                    </div>
                                </div>
                            </TabPanel>
                        )}
                    </TabContext>
                </div>

                <DialogActions>
                    <Button onClick={props.handleClose}>Close</Button>
                </DialogActions>

            </DialogContent>
        </Dialog >
    )
}

export default BidDetailsDialog