import { Button, Dialog, DialogActions, DialogContent, FormControl, FormHelperText, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import React, { useContext, useState } from 'react'
import { API_PATHS, LOT_API_PATHS } from '../../../common/ApiPaths'
import useFetch from '../../../hooks/useFetch'
import { useHistory } from "react-router-dom"
import { UserContext } from '../../../context/UserContext'
import IValidationObject from '../../../interfaces/validationObject'
import IOrganisation from '../../../interfaces/organisation'
import useCommonFunctions from '../../../hooks/useCommonFunctions'
import ILot from '../../../interfaces/lot'
import moment from 'moment'
import ICampaign from '../../../interfaces/campaign'
import { CampaignContext } from '../../../context/CampaignContext'
import { OrganisationContext } from '../../../context/OrganisationContext'
import useLotValidation from '../lotSettings/useLotValidation'
import { LotType } from '../../../common/enums/LotType'
import CustomPageSelect from '../../shared/customPageSelect/CustomPageSelect'

interface InputProps {
    isDialogOpen: boolean
    handleClose: () => void
    campaignLotList: ILot[]
    setDialogProperties: (dialogProps: any) => void
    setIsConfirmationDialogOpen: (open: boolean) => void
}

const CreateLotDialog = (props: InputProps) => {

    const [lot, setLot] = useState<ILot>({
        bidIncrement: 1,
        reservePrice: 1,
        isShowAvailabilityCount: false,
        type: LotType.COMPETITIVE,
        costPrice: 0,
        availabilityCount: 1,
        startDateTime: new Date(),
        endDateTime: (moment(new Date()).add(1, 'days')).toDate()
    } as ILot)
    const [orderNumberSetting] = useState<string>('END')

    const { dBUser } = useContext(UserContext)
    const campaign: ICampaign = useContext(CampaignContext)
    const organisation: IOrganisation = useContext(OrganisationContext)

    let history = useHistory()
    const lotApi = useFetch(API_PATHS.LOTS);
    const lotValidation = useLotValidation(lot, orderNumberSetting)
    const commonFunctions = useCommonFunctions()


    const createLot = async (): Promise<void> => {
        if (lotValidation.validateInputs(false)) {
            let lotToCreate: ILot = {
                ...lot,
                title: lot.title.trim(),
                campaignId: campaign._id,
                isHidden: false,
                isActive: true
            }

            if (orderNumberSetting === "END") {
                let topOrderNumber = 0;
                props.campaignLotList.forEach(element => {
                    if (element.orderNumber > topOrderNumber) {
                        topOrderNumber = element.orderNumber
                    }
                });
                lotToCreate.orderNumber = topOrderNumber + 1;
            }

            try {
                const data: ILot = await lotApi.post(LOT_API_PATHS.CREATE_LOT, [lotToCreate], dBUser._id)
                history.push(`/organisations/${organisation.slug}/campaigns/${campaign.slug}/lots/${data[0].slug}`)
            }
            catch (err: any) {
                props.setDialogProperties({ type: "ERROR", title: "Create failed.", message: `Your lot creation failed with some error`, errorDetails: err.message })
                props.setIsConfirmationDialogOpen(true)
            }
        }
    }

    const getValidation = (name): IValidationObject => {
        let returnValue: IValidationObject = {} as IValidationObject
        lotValidation.validationObject.forEach(element => {
            if (name === element.name) {
                returnValue = element
            }
        })
        return returnValue
    }

    const handleBlur = (event) => {
        const { name, value } = event.target
        setLot({ ...lot, [name]: value })
    }

    const handleCustomPageChange = (event) => {
        if (event.target.value === "lot-page") {
        setLot({ ...lot, customPageId: "" })
        }else{
            setLot({ ...lot, customPageId: event.target.value })
        }
    }

    return (
        <Dialog
            open={props.isDialogOpen}
            onClose={props.handleClose}
            aria-labelledby="form-dialog-title"
        >

            <DialogContent>
                <div className='coloured-section' >
                    <h2 className='title'>Lot Details</h2>
                </div>
                <div className='content-wrapper'>
                    <TextField
                        id="title"
                        name="title"
                        label="Lot Title"
                        variant="outlined"
                        defaultValue={lot.title}
                        onBlur={handleBlur}
                        required
                        fullWidth
                        error={!getValidation("title").isValid}
                        helperText={getValidation("title").isValid ? "The main title / name of the lot." : getValidation("title").validationMessage}
                    />

                    <FormControl
                        variant="outlined"
                        required
                        fullWidth
                        error={!getValidation("type").isValid}
                    >
                        <InputLabel id="lot-type-label">Lot Type</InputLabel>
                        <Select
                            labelId="lot-type-label"
                            id="lotType"
                            name="type"
                            defaultValue={lot.type}
                            onChange={handleBlur}
                            label="Lot Type *"
                        >
                            <MenuItem key={1} value={"COMPETITIVE"}>Competitive</MenuItem>
                            <MenuItem key={3} value={"SEALED"}>Sealed</MenuItem>
                            <MenuItem key={4} value={"BUYITNOW"}>Buy it Now</MenuItem>
                            <MenuItem key={5} value={"VIEWONLY"}>View Only</MenuItem>
                            <MenuItem key={5} value={"PARENT"}>Parent</MenuItem>
                        </Select>
                        {!getValidation("type").isValid && <FormHelperText>{getValidation("type").validationMessage}</FormHelperText>}
                    </FormControl>

                    <CustomPageSelect
                        // lot={lot}
                        // setLot={setLot}
                        handleChange={handleCustomPageChange}
                        isNewLot={true}
                        // isDefaultToLotsPage={true}
                    />

                    {lot.type && (
                        <>
                            {lot.type !== "PARENT" && 
                                <>
                                    {/*lot.type !== "CUMULATIVE" && */lot.type !== "VIEWONLY" &&
                                        <TextField
                                            id="reservePrice"
                                            name="reservePrice"
                                            label="Reserve / Start Price"
                                            variant="outlined"
                                            type="number"
                                            onWheel={(e) => (e.target as any).blur()}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">{commonFunctions.getCurrencySymbol(campaign.currency ?? 'GBP')}</InputAdornment>,
                                                inputProps: { min: 1 }
                                            }}
                                            defaultValue={lot.reservePrice}
                                            onBlur={handleBlur}
                                            onChange={(event) => {
                                                event.target.value = Number(event.target.value).toString()
                                            }}
                                            required
                                            fullWidth
                                            error={!getValidation("reservePrice").isValid}
                                            helperText={getValidation("reservePrice").isValid ? "The price at or above which, bids will be accepted." : getValidation("reservePrice").validationMessage}
                                        />
                                    }

                                    {lot.type !== "VIEWONLY" &&
                                        <TextField
                                            id="costPrice"
                                            name="costPrice"
                                            label="Cost Price"
                                            variant="outlined"
                                            type="number"
                                            onWheel={(e) => (e.target as any).blur()}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">{commonFunctions.getCurrencySymbol(campaign.currency ?? 'GBP')}</InputAdornment>,
                                            }}
                                            defaultValue={lot.costPrice}
                                            onBlur={handleBlur}
                                            onChange={(event) => {
                                                event.target.value = Number(event.target.value).toString()
                                            }}
                                            required
                                            fullWidth
                                            error={!getValidation("costPrice").isValid}
                                            helperText={getValidation("costPrice").isValid ? "The cost price of the lot." : getValidation("costPrice").validationMessage}
                                        />
                                    }
                                    {/*lot.type !== "CUMULATIVE" && */lot.type !== "VIEWONLY" &&
                                        <TextField
                                            id="availabilityCount"
                                            name="availabilityCount"
                                            label="Number Available"
                                            variant="outlined"
                                            type="number"
                                            onWheel={(e) => (e.target as any).blur()}
                                            defaultValue={lot.availabilityCount}
                                            onBlur={handleBlur}
                                            onChange={(event) => {
                                                event.target.value = Number(event.target.value).toString()
                                            }}
                                            required
                                            fullWidth
                                            InputProps={{ inputProps: { min: 1 } }}
                                            error={!getValidation("availabilityCount").isValid}
                                            helperText={getValidation("availabilityCount").isValid ? "The number of the lot that are available to be sold." : getValidation("availabilityCount").validationMessage}
                                        />
                                    }

                                    {lot.type !== "BUYITNOW" && lot.type !== "VIEWONLY" && lot.type !== "SEALED" &&
                                        <TextField
                                            id="bidIncrement"
                                            name="bidIncrement"
                                            label="Bid Increment"
                                            variant="outlined"
                                            type="number"
                                            onWheel={(e) => (e.target as any).blur()}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">{commonFunctions.getCurrencySymbol(campaign.currency ?? 'GBP')}</InputAdornment>,
                                                inputProps: { min: 1 }
                                            }}
                                            value={lot.bidIncrement}
                                            onChange={(event) => {
                                                event.target.value = Number(event.target.value).toString()
                                                handleBlur(event)
                                            }}
                                            required
                                            fullWidth
                                            error={!getValidation("bidIncrement").isValid}
                                            helperText={getValidation("bidIncrement").isValid ? `The next highest bid value that will be accepted. Eg current bid ${commonFunctions.getCurrencySymbol(campaign.currency ?? 'GBP')}10, bid increment of ${commonFunctions.getCurrencySymbol(campaign.currency ?? 'GBP')}5, next acceptable bid ${commonFunctions.getCurrencySymbol(campaign.currency ?? 'GBP')}15.` : getValidation("bidIncrement").validationMessage}
                                        />
                                    }
                                </>
                            }
                        </>
                    )}

                </div>

            </DialogContent>
            <DialogActions>
                <div className="action-section">
                    <>{!lotValidation.isValidationPassed && <p className="validation-text">Something's not right, please check highlighted fields above.</p>}</>

                    <div className="save-button-wrapper">

                        <div className='buttons'>

                            <Button onClick={props.handleClose} id="cancel-button">
                                Cancel
                            </Button>

                            <Button
                                onClick={() => createLot()}
                                className="gg-button"
                                id="save-button"
                            >
                                <span>CREATE</span>
                            </Button>
                        </div>

                    </div>
                </div>
            </DialogActions>
        </Dialog>
    )
}

export default CreateLotDialog