import { Dialog, DialogContent } from '@mui/material'
import React, { useEffect, useRef } from 'react'
import useCommonFunctions from '../../../hooks/useCommonFunctions'
import { IBigScreenLot } from '../../../interfaces/bigScreenLot'
import { IBigScreenPayload } from '../../../interfaces/bigScreenPayload'
import './bidNotificationDialog.scss'
interface InputProps {
    isDialogOpen: boolean,
    lot: IBigScreenLot,
    bigScreenPayload: IBigScreenPayload
}

const BidNotificationDialog = (props: InputProps) => {

    const commonFunctions=useCommonFunctions()
    const isCancelled = useRef(false)

    const determineBidType = (bidType: string): string => {
        if (bidType === "CUMULATIVE") {
            return "pledge"
        } else if (bidType === "BUYITNOW") {
            return "purchase"
        } else {
            return "bid"
        }
    }

    useEffect(() => {

        return () => {
            isCancelled.current = true
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Dialog
            open={props.isDialogOpen}
            aria-labelledby="dialog-title"
            className="bid-notification-dialog"
        >
            <DialogContent>
                <div className='left-right-split'>
                    {props.lot.imageUrl ? (
                        <div className='image-wrapper'>
                            <img src={props.lot.imageUrl} title={props.lot.title} alt={props.lot.title} />
                        </div>
                    ) : (
                        <div className='image-wrapper'>
                            <img src='/placeholder_image.jpg' title={props.lot.title} alt={props.lot.title} />
                        </div>
                    )}

                    <div className='text-wrapper'>
                        <h1 className='title' style={{ fontSize: `${props.bigScreenPayload.horizontalLotCardFontSize * 2}rem`, lineHeight: `${props.bigScreenPayload.horizontalLotCardFontSize * 2}rem` }}>New {determineBidType(props.lot.type)}</h1>
                        <h2 className='lot-name' style={{ fontSize: `${props.bigScreenPayload.horizontalLotCardFontSize * 1}rem`, lineHeight: `${props.bigScreenPayload.horizontalLotCardFontSize * 1}rem` }}>
                            {props.lot.title}
                        </h2>
                        {props.lot.type === "SEALED" ?
                            <h3 className='bidder' style={{ fontSize: `${props.bigScreenPayload.horizontalLotCardFontSize * 0.75}rem`, lineHeight: `${props.bigScreenPayload.horizontalLotCardFontSize * 0.75}rem` }}>Sealed Bid</h3>
                            :
                            <>
                                <h3 className='bidder' style={{ fontSize: `${props.bigScreenPayload.horizontalLotCardFontSize * 0.75}rem`, lineHeight: `${props.bigScreenPayload.horizontalLotCardFontSize * 0.75}rem` }}>{commonFunctions.titleCase(props.lot.winningBidderName)}</h3>
                                <h3 className='bid-amount' style={{ fontSize: `${props.bigScreenPayload.horizontalLotCardFontSize * 0.75}rem`, lineHeight: `${props.bigScreenPayload.horizontalLotCardFontSize * 0.75}rem` }}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: props.bigScreenPayload.currencyCode ? props.bigScreenPayload.currencyCode : "GBP", minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(props.lot.winningBid)}</h3>
                            </>
                        }
                    </div>
                </div>



            </DialogContent>
        </Dialog>
    )
}

export default BidNotificationDialog