import { useEffect, useState } from "react"
import { IBigScreenPayload } from "../../../interfaces/bigScreenPayload"
import { BigScreenDisplayMode } from "../../../common/enums/BigScreenDisplayMode"
import BigScreenCarouselHorizontal from "./BigScreenCarouselHorizontal"
import BigScreenCarouselVertical from "./BigScreenCarouselVertical"

interface InputProps {
    bigScreenPayload: IBigScreenPayload
}

const BigScreenModeSwitcher = (props: InputProps) => {

    const [currentDisplayMode, setCurrentDisplayMode] = useState<BigScreenDisplayMode>(BigScreenDisplayMode.HORIZONTAL_SCROLL)

    useEffect(() => {
        let timer = setTimeout(() => {
            setCurrentDisplayMode(currentDisplayMode === BigScreenDisplayMode.HORIZONTAL_SCROLL ? BigScreenDisplayMode.VERTICAL_SCROLL : BigScreenDisplayMode.HORIZONTAL_SCROLL)
        }, props.bigScreenPayload.displayModeToggleTime)
        return () => {
            clearTimeout(timer)
        }
    }, [currentDisplayMode])


    return (
        currentDisplayMode === BigScreenDisplayMode.HORIZONTAL_SCROLL ?
            <BigScreenCarouselHorizontal bigScreenPayload={props.bigScreenPayload} />
            :
            <BigScreenCarouselVertical bigScreenPayload={props.bigScreenPayload} />

    )
}

export default BigScreenModeSwitcher