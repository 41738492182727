import { Button, FormControl, FormHelperText, InputAdornment, InputLabel, MenuItem, Paper, Select, TextField } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { IBigScreenSettings } from "../../../interfaces/bigScreenSettings";
import Loading from "../../shared/Loading";
import CloudinaryUploadWidget from "../shared/CloudinaryUploadWidget";
import GGSlider from "../shared/GGSlider";
import GGSwitch from "../shared/GGSwitch";
import ConfirmationDialog from "../../shared/ConfirmationDialog";
import { Link } from "react-router-dom";
import AdHocDialog from "../bigScreen/AdHocDialog";
import WysiwygEditor from "../../shared/WysiwygEditor";
import { OrganisationContext } from "../../../context/OrganisationContext";
import useCommonFunctions from "../../../hooks/useCommonFunctions";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import DateAdapter from '@mui/lab/AdapterMoment'
import { CampaignContext } from '../../../context/CampaignContext'
import { useErrorBoundary } from 'react-error-boundary'
import ColorInput from "../shared/colorInput/ColorInput";
import { BigScreenDisplayMode } from "../../../common/enums/BigScreenDisplayMode";

const BigScreenSettings = ({ match, ...props }) => {
    const [bigScreenSettings, setBigScreenSettings] =
        useState<IBigScreenSettings>({
            campaignSlug: match.params.campaignSlug,
            bidPopupTime: 5,
            lotScrollSpeed: 5,
            headerFontSize: 2,
            isShowCountdownTimer: false,
            countdownTimerStartDate: new Date(),
            countdownTimerEndDate: new Date(),
            adHocPopupImageSize: 200,
            adHocPopupWidthPercentage: 50,
            displayMode: BigScreenDisplayMode.BOTH,
            displayModeToggleTime: 5 * 60 * 1000,
            verticalLotCardFontSize: 2.8,
            horizontalLotCardFontSize: 2.8,
            verticalLotCardTitleCharacterLimit: 80,
            horizontalLotCardTitleCharacterLimit: 80
        } as IBigScreenSettings);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const [dialogProperties, setDialogProperties] = useState<any>({
        isOpen: false,
        type: "",
        title: "",
        message: "",
        errorDetails: "",
    });
    const [isAdHocDialogPreviewOpen, setIsAdHocDialogPreviewOpen] =
        useState<boolean>(false);

    const isCancelled = useRef(false);
    const bigScreenApi = useFetch("big-screens");
    const organisation = useContext(OrganisationContext);
    const commonFunctions = useCommonFunctions();
    const campaign = useContext(CampaignContext)
    const { showBoundary } = useErrorBoundary();


    const getBigScreenSettings = () => {
        setLoading(true)
        bigScreenApi.get(`/${campaign._id}/settings`)
            .then((data) => {
                if (data.campaignId) {
                    setBigScreenSettings(data)
                    props.setPadding()
                }
                setLoading(false)
            }).catch((err: Error) => {
                showBoundary(err)
                setLoading(false)
            })
    }


    const saveSettings = () => {
        bigScreenApi
            .post(`/${campaign._id}/settings`, { ...bigScreenSettings })
            .then((data) => {
                setDialogProperties({
                    isOpen: true,
                    type: "SUCCESS",
                    title: "Save complete.",
                    message: "Big screen settings successfully saved.",
                });
            })
            .catch((err: Error) => {
                setDialogProperties({
                    isOpen: true,
                    type: "ERROR",
                    title: "Save failed.",
                    message: `Your big screen did not save.`,
                    errorDetails: err.message,
                });
            });
    };


    useEffect(() => {
        if (campaign && campaign._id)
            getBigScreenSettings()

        return () => {
            isCancelled.current = true
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaign])

    const handleChange = (name, value) => {
        setBigScreenSettings({ ...bigScreenSettings, [name]: value });
    };


    if (loading) {
        return <Loading />;
    }

    // if (error) {
    //   return <p>{error}</p>;
    // }

    return (
        <div className="big-screen-settings">
            <h2>Core Settings</h2>
            <Paper className="input-wrapper">
                <div className="field-wrapper">
                    <TextField
                        id="title"
                        name="title"
                        label="Title"
                        variant="outlined"
                        defaultValue={bigScreenSettings.title}
                        onBlur={(event) =>
                            handleChange(event.target.name, event.target.value)
                        }
                        helperText="The main page title (can also be used for countdown timer)."
                    />
                </div>

                <div className="field-wrapper">
                    <TextField
                        id="headerFontSize"
                        name="headerFontSize"
                        label="Header Font Size"
                        variant="outlined"
                        type="number"
                        onWheel={(e) => (e.target as any).blur()}
                        defaultValue={bigScreenSettings.headerFontSize}
                        onBlur={(event) =>
                            handleChange(event.target.name, Number(event.target.value))
                        }
                        helperText="The font size of the header bar."
                    />
                </div>
                <div className="field-wrapper">
                    <TextField
                        id="headerLeftImageUrl"
                        name="headerLeftImageUrl"
                        label="Header Left Image URL"
                        variant="outlined"
                        value={bigScreenSettings.headerLeftImageUrl}
                        onChange={(event) =>
                            handleChange(event.target.name, event.target.value)
                        }
                        helperText="The url for the image in the top left. Either use the icon to upload a new file or provide the url of an existing file hosted online. Suggested dimensions 3:2."
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    <CloudinaryUploadWidget
                                        folderName={match.params.campaignSlug}
                                        allowMultipleUploads={false}
                                        functionOnComplete={(uploadedUrlList) => {
                                            if (uploadedUrlList.length) {
                                                handleChange(
                                                    "headerLeftImageUrl",
                                                    uploadedUrlList[uploadedUrlList.length - 1]
                                                );
                                            }
                                        }}
                                        uploadPreset="unsigned"
                                    />
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
                <div className="field-wrapper">
                    <TextField
                        id="headerLeftImageSize"
                        name="headerLeftImageSize"
                        label="Header Left Image Size"
                        variant="outlined"
                        type="number"
                        onWheel={(e) => (e.target as any).blur()}
                        defaultValue={bigScreenSettings.headerLeftImageSize}
                        onBlur={(event) =>
                            handleChange(event.target.name, Number(event.target.value))
                        }
                        helperText="The size of the image in the top left."
                    />
                </div>
                <div className="field-wrapper">
                    <TextField
                        id="headerRightImageUrl"
                        name="headerRightImageUrl"
                        label="Header Right Image URL"
                        variant="outlined"
                        value={bigScreenSettings.headerRightImageUrl}
                        onChange={(event) =>
                            handleChange(event.target.name, event.target.value)
                        }
                        helperText="The url for the image in the top right. Either use the icon to upload a new file or provide the url of an existing file hosted online. Suggested dimensions 3:2."
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    <CloudinaryUploadWidget
                                        folderName={match.params.campaignSlug}
                                        allowMultipleUploads={false}
                                        functionOnComplete={(uploadedUrlList) => {
                                            if (uploadedUrlList.length) {
                                                handleChange(
                                                    "headerRightImageUrl",
                                                    uploadedUrlList[uploadedUrlList.length - 1]
                                                );
                                            }
                                        }}
                                        uploadPreset="unsigned"
                                    />
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
                <div className="field-wrapper">
                    <TextField
                        id="headerRightImageSize"
                        name="headerRightImageSize"
                        label="Header Right Image Size"
                        variant="outlined"
                        type="number"
                        onWheel={(e) => (e.target as any).blur()}
                        defaultValue={bigScreenSettings.headerRightImageSize}
                        onBlur={(event) =>
                            handleChange(event.target.name, Number(event.target.value))
                        }
                        helperText="The size of the image in the top right."
                    />
                </div>


                <div className='field-wrapper'>
                    <ColorInput
                        name="headerFontColor"
                        label="Header Bar Font Colour"
                        value={bigScreenSettings.headerFontColor || ""}
                        handleChange={handleChange}
                        helperText="This will set the font colour of the header bar."
                    />
                </div>
                <div className='field-wrapper'>
                    <ColorInput
                        name="headerBackgroundColor"
                        label="Header Bar Background Colour"
                        value={bigScreenSettings.headerBackgroundColor || ""}
                        handleChange={handleChange}
                        helperText="This will set the background colour of the header bar."
                    />
                </div>

                <div className='field-wrapper'>
                    <ColorInput
                        name="bodyBackgroundColor"
                        label="Body Background Colour"
                        value={bigScreenSettings.bodyBackgroundColor || ""}
                        handleChange={handleChange}
                        helperText="This will set the background colour of the body, behind the lot cards."
                    />
                </div>

                <div className="field-wrapper">
                    <TextField
                        id="bodyBackgroundUrl"
                        name="bodyBackgroundUrl"
                        label="Body Background Image URL"
                        variant="outlined"
                        value={bigScreenSettings.bodyBackgroundUrl}
                        onChange={(event) =>
                            handleChange(event.target.name, event.target.value)
                        }
                        helperText="The url for the background image, behind the lot cards. If none selected, will revert to the body background colour."
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    <CloudinaryUploadWidget
                                        folderName={match.params.campaignSlug}
                                        allowMultipleUploads={false}
                                        functionOnComplete={(uploadedUrlList) => {
                                            if (uploadedUrlList.length) {
                                                handleChange(
                                                    "bodyBackgroundUrl",
                                                    uploadedUrlList[uploadedUrlList.length - 1]
                                                );
                                            }
                                        }}
                                        uploadPreset="unsigned"
                                    />
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>

                <div className="field-wrapper">
                    <GGSlider
                        id="bidPopupTime"
                        name="bidPopupTime"
                        defaultValue={bigScreenSettings.bidPopupTime || 5}
                        min={1}
                        max={10}
                        step={1}
                        marks={[
                            { value: 1, label: "1" },
                            { value: 2, label: "2" },
                            { value: 3, label: "3" },
                            { value: 4, label: "4" },
                            { value: 5, label: "5" },
                            { value: 6, label: "6" },
                            { value: 7, label: "7" },
                            { value: 8, label: "8" },
                            { value: 9, label: "9" },
                            { value: 10, label: "10" },
                        ]}
                        valueLabelDisplay={"off"}
                        label="Bid Popup Time (seconds)"
                        helperText="The number of seconds for which the bid popup will display."
                        // onChange={(event, value) => handleChange(event.target.name, value)}
                        onChange={handleChange}
                    />
                </div>
            </Paper>


            <h2>Display Mode Settings</h2>
            <Paper className="input-wrapper">
                <div className="field-wrapper">
                    <FormControl variant="outlined">
                        <InputLabel id="displayMode-label">Display Mode</InputLabel>
                        <Select
                            labelId="displayMode-label"
                            id="displayMode"
                            name="displayMode"
                            defaultValue={bigScreenSettings.displayMode}
                            onChange={event => handleChange(event.target.name, event.target.value)}
                            label="Display Mode"
                        >
                            <MenuItem value={BigScreenDisplayMode.HORIZONTAL_SCROLL}>Horizontal Scroll Mode</MenuItem>
                            <MenuItem value={BigScreenDisplayMode.VERTICAL_SCROLL}>Vertical Scroll Mode</MenuItem>
                            <MenuItem value={BigScreenDisplayMode.BOTH}>Both</MenuItem>
                        </Select>
                        <FormHelperText>How should the lots display on the big screen.</FormHelperText>
                    </FormControl>
                </div>
                {bigScreenSettings.displayMode === BigScreenDisplayMode.BOTH &&
                    <div className="field-wrapper">
                        <TextField
                            id="displayModeToggleTime"
                            name="displayModeToggleTime"
                            label="Display Mode Toggle Time (minutes)"
                            variant="outlined"
                            type="number"
                            onWheel={(e) => (e.target as any).blur()}
                            defaultValue={bigScreenSettings.displayModeToggleTime / 60 / 1000}
                            onBlur={(event) =>
                                handleChange(event.target.name, (Number(event.target.value) * 60 * 1000))
                            }
                            helperText="The amount of time each display mode will display for before switching."
                        />
                    </div>
                }
                {bigScreenSettings.displayMode !== BigScreenDisplayMode.VERTICAL_SCROLL &&
                    <>
                        <div className="field-wrapper">
                            <TextField
                                id="horizontalLotCardTitleCharacterLimit"
                                name="horizontalLotCardTitleCharacterLimit"
                                label="Lot Title Character Limit (Horizontal Scroll Mode)"
                                variant="outlined"
                                type="number"
                                onWheel={(e) => (e.target as any).blur()}
                                defaultValue={bigScreenSettings.horizontalLotCardTitleCharacterLimit}
                                onBlur={(event) =>
                                    handleChange(event.target.name, Number(event.target.value))
                                }
                                helperText="The number of characters that will be displayed in each lot title before the ..."
                            />
                        </div>

                        <div className="field-wrapper">
                            <TextField
                                id="horizontalLotCardFontSize"
                                name="horizontalLotCardFontSize"
                                label="Lot Card Font Size (Horizontal Scroll Mode)"
                                variant="outlined"
                                type="number"
                                onWheel={(e) => (e.target as any).blur()}
                                defaultValue={bigScreenSettings.horizontalLotCardFontSize}
                                onBlur={(event) =>
                                    handleChange(event.target.name, Number(event.target.value))
                                }
                                helperText="The font size of the lot card."
                            />
                        </div>

                    </>
                }
                {bigScreenSettings.displayMode !== BigScreenDisplayMode.HORIZONTAL_SCROLL &&
                    <>
                        <div className="field-wrapper">
                            <TextField
                                id="verticalLotCardTitleCharacterLimit"
                                name="verticalLotCardTitleCharacterLimit"
                                label="Lot Title Character Limit (Vertical Scroll Mode)"
                                variant="outlined"
                                type="number"
                                onWheel={(e) => (e.target as any).blur()}
                                defaultValue={bigScreenSettings.verticalLotCardTitleCharacterLimit}
                                onBlur={(event) =>
                                    handleChange(event.target.name, Number(event.target.value))
                                }
                                helperText="The number of characters that will be displayed in each lot title before the ..."
                            />
                        </div>

                        <div className="field-wrapper">
                            <TextField
                                id="verticalLotCardFontSize"
                                name="verticalLotCardFontSize"
                                label="Lot Card Font Size (Vertical Scroll Mode)"
                                variant="outlined"
                                type="number"
                                onWheel={(e) => (e.target as any).blur()}
                                defaultValue={bigScreenSettings.verticalLotCardFontSize}
                                onBlur={(event) =>
                                    handleChange(event.target.name, Number(event.target.value))
                                }
                                helperText="The font size of the lot card."
                            />
                        </div>
                    </>
                }
                {bigScreenSettings.displayMode !== BigScreenDisplayMode.VERTICAL_SCROLL &&
                    <div className="field-wrapper">
                        <GGSlider
                            id="lotScrollSpeed"
                            name="lotScrollSpeed"
                            defaultValue={bigScreenSettings.lotScrollSpeed || 5}
                            min={1}
                            max={10}
                            step={1}
                            marks={[
                                { value: 1, label: "1" },
                                { value: 2, label: "2" },
                                { value: 3, label: "3" },
                                { value: 4, label: "4" },
                                { value: 5, label: "5" },
                                { value: 6, label: "6" },
                                { value: 7, label: "7" },
                                { value: 8, label: "8" },
                                { value: 9, label: "9" },
                                { value: 10, label: "10" },
                            ]}
                            valueLabelDisplay={"off"}
                            label="Horizontal Lot Scroll Speed (seconds)"
                            helperText="The speed at which the lots scroll in seconds."
                            onChange={handleChange}
                        />
                    </div>
                }

            </Paper>




            <h2>Countdown Timer</h2>
            <Paper className="input-wrapper">
                <div className="field-wrapper">
                    <GGSwitch
                        defaultValue={bigScreenSettings.isShowCountdownTimer}
                        helperText=""
                        id="isShowCountdownTimer"
                        label="Show Countdown Timer?"
                        name="isShowCountdownTimer"
                        onChange={(event, value) => handleChange(event.target.name, value)}
                    />
                </div>
                <div className="field-wrapper"></div>
                <div className="field-wrapper">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            label="Start Time"
                            value={dayjs(bigScreenSettings.countdownTimerStartDate)}
                            onChange={(date) => handleChange("countdownTimerStartDate", date)}
                            ampm={false}
                            // hideTabs
                            // showTodayButton
                            format="HH:mm DD/MM/YYYY"
                        // renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </div>
                <div className="field-wrapper">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            label="End Time"
                            value={dayjs(bigScreenSettings.countdownTimerEndDate)}
                            onChange={(date) => handleChange("countdownTimerEndDate", date)}
                            ampm={false}
                            // hideTabs
                            // showTodayButton
                            format="HH:mm DD/MM/YYYY"
                        // renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </div>

                <div className="field-wrapper">
                    <TextField
                        id="campaignLiveTitle"
                        name="campaignLiveTitle"
                        label="Campaign Live Title"
                        variant="outlined"
                        defaultValue={bigScreenSettings.campaignLiveTitle}
                        onBlur={(event) =>
                            handleChange(event.target.name, event.target.value)
                        }
                        helperText="The text that will be shown above the countdown timer while the campaign is live."
                    />
                </div>
                <div className="field-wrapper">
                    <TextField
                        id="campaignNotYetOpenTitle"
                        name="campaignnNotYetOpenTitle"
                        label="Campaign Not Yet Open Title"
                        variant="outlined"
                        defaultValue={bigScreenSettings.campaignNotYetOpenTitle}
                        onBlur={(event) =>
                            handleChange(event.target.name, event.target.value)
                        }
                        helperText="The text that will be shown above the countdown timer before the campaign is live."
                    />
                </div>
                <div className="field-wrapper">
                    <TextField
                        id="countdownTimerClosedText"
                        name="countdownTimerClosedText"
                        label="Closed Message"
                        variant="outlined"
                        defaultValue={bigScreenSettings.countdownTimerClosedText}
                        onBlur={(event) =>
                            handleChange(event.target.name, event.target.value)
                        }
                        helperText="The text that will be shown instead of the timer once the time has closed."
                    />
                </div>
            </Paper>
            <h2>Ad-Hoc Popup</h2>
            <Paper className="input-wrapper">
                <div className="field-wrapper">
                    <GGSwitch
                        defaultValue={bigScreenSettings.isShowAdHocPopup}
                        helperText=""
                        id="isShowAdHocPopup"
                        label="Show Ad-Hoc Popup?"
                        name="isShowAdHocPopup"
                        onChange={(event, value) => handleChange(event.target.name, value)}
                    />
                </div>
                <div className="field-wrapper">
                    <GGSwitch
                        defaultValue={bigScreenSettings.adHocPopupisShowCountdown}
                        helperText=""
                        id="adHocPopupisShowCountdown"
                        label="Show Countdown Timer?"
                        name="adHocPopupisShowCountdown"
                        onChange={(event, value) => handleChange(event.target.name, value)}
                    />
                </div>

                <div className="field-wrapper quill">
                    <span>Title</span>
                    <WysiwygEditor
                        placeholder=""
                        value={bigScreenSettings.adHocPopupTitle}
                        onChange={(value) =>
                            setBigScreenSettings({
                                ...bigScreenSettings,
                                adHocPopupTitle: value,
                            })
                        }
                    />
                    <span>The title of the popup.</span>
                </div>
                <div className="field-wrapper quill">
                    <span>Body</span>
                    <WysiwygEditor
                        placeholder=""
                        value={bigScreenSettings.adHocPopupBody}
                        onChange={(value) =>
                            setBigScreenSettings({
                                ...bigScreenSettings,
                                adHocPopupBody: value,
                            })
                        }
                    />
                    <span>The body of the popup.</span>
                </div>

                <div className="field-wrapper">
                    <TextField
                        id="adHocPopupImageUrl"
                        name="adHocPopupImageUrl"
                        label="Image URL"
                        variant="outlined"
                        value={bigScreenSettings.adHocPopupImageUrl}
                        onChange={(event) =>
                            handleChange(event.target.name, event.target.value)
                        }
                        helperText="The url for the popup image. Either use the icon to upload a new file or provide the url of an existing file hosted online."
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    <CloudinaryUploadWidget
                                        folderName={match.params.campaignSlug}
                                        allowMultipleUploads={false}
                                        functionOnComplete={(uploadedUrlList) => {
                                            if (uploadedUrlList.length) {
                                                handleChange(
                                                    "adHocPopupImageUrl",
                                                    uploadedUrlList[uploadedUrlList.length - 1]
                                                );
                                            }
                                        }}
                                        uploadPreset="unsigned"
                                    />
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
                <div className="field-wrapper">
                    <TextField
                        id="adHocPopupImageSize"
                        name="adHocPopupImageSize"
                        label="Image size (px)"
                        variant="outlined"
                        type="number"
                        onWheel={(e) => (e.target as any).blur()}
                        defaultValue={bigScreenSettings.adHocPopupImageSize}
                        onBlur={(event) =>
                            handleChange(event.target.name, Number(event.target.value))
                        }
                        helperText=""
                    />
                </div>
                <div className="field-wrapper">
                    <GGSlider
                        id="adHocPopupWidthPercentage"
                        name="adHocPopupWidthPercentage"
                        defaultValue={bigScreenSettings.adHocPopupWidthPercentage || 50}
                        min={10}
                        max={100}
                        step={10}
                        marks={[
                            { value: 10, label: "10%" },
                            { value: 20, label: "20%" },
                            { value: 30, label: "30%" },
                            { value: 40, label: "40%" },
                            { value: 50, label: "50%" },
                            { value: 60, label: "60%" },
                            { value: 70, label: "70%" },
                            { value: 80, label: "80%" },
                            { value: 90, label: "90%" },
                            { value: 100, label: "100%" },
                        ]}
                        valueLabelDisplay={"off"}
                        label="Popup width as a percentage of screen width."
                        helperText=""
                        // onChange={(event, value) => handleChange(event.target.name, value)}
                        onChange={handleChange}
                    />
                </div>
                <div className="button-wrapper space-between">
                    <Button onClick={() => setIsAdHocDialogPreviewOpen(true)}>
                        Preview Ad-Hoc Popup
                    </Button>
                    <div></div>
                </div>
            </Paper>

            <div className="fixed-action-section">
                <div style={{ display: "flex" }}>
                    <div style={{ width: 240 }} className="hide-on-mobile"></div>
                    <Link
                        to={`/organisations/${organisation.slug}/campaigns/${match.params.campaignSlug}/big-screen`}
                        target="_blank"
                    >
                        <Button>Open Big Screen (in new tab)</Button>
                    </Link>
                </div>
                <Button className="gg-button" onClick={saveSettings}>
                    Save
                </Button>
            </div>

            <AdHocDialog
                isShowAdHocPopup={isAdHocDialogPreviewOpen}
                adHocPopupWidthPercentage={bigScreenSettings.adHocPopupWidthPercentage}
                adHocPopupTitle={bigScreenSettings.adHocPopupTitle}
                adHocPopupImageUrl={bigScreenSettings.adHocPopupImageUrl}
                adHocPopupImageSize={bigScreenSettings.adHocPopupImageSize}
                adHocPopupisShowCountdown={bigScreenSettings.adHocPopupisShowCountdown}
                adHocPopupBody={bigScreenSettings.adHocPopupBody}
                countdownStartDate={bigScreenSettings.countdownTimerStartDate}
                countdownEndDate={bigScreenSettings.countdownTimerEndDate}
                campaignNotYetOpenTitle={bigScreenSettings.campaignNotYetOpenTitle}
                countdownClosedMessage={bigScreenSettings.countdownTimerClosedText}
                campaignLiveTitle={bigScreenSettings.campaignLiveTitle}
                closeAction={() => setIsAdHocDialogPreviewOpen(false)}
            />

            <ConfirmationDialog
                handleClose={() => setDialogProperties({ isOpen: false })}
                isDialogOpen={dialogProperties.isOpen}
                message={dialogProperties.message}
                errorDetails={dialogProperties.errorDetails}
                title={dialogProperties.title}
                type={dialogProperties.type}
                label={dialogProperties.label}
                primaryButton={
                    <Button
                        onClick={() => setDialogProperties({ isOpen: false })}
                        className="gg-button"
                        style={commonFunctions.determineButtonColor(dialogProperties.type)}
                    >
                        Ok
                    </Button>
                }
            />
        </div>
    );
};

export default BigScreenSettings;
