import useCommonFunctions from "../../../hooks/useCommonFunctions"
import { IBigScreenBid } from "../../../interfaces/bigScreenBid"
import { IBigScreenLot } from "../../../interfaces/bigScreenLot"
import { IBigScreenPayload } from "../../../interfaces/bigScreenPayload"
import ICampaign from "../../../interfaces/campaign"
import './bigScreenVerticalCard.scss'

interface InputProps {
    lot: IBigScreenLot
    bigScreenPayload: IBigScreenPayload
    campaign: ICampaign
}

const BigScreenVerticalCard = (props: InputProps) => {

    const commonFunctions = useCommonFunctions()

    const determinePurchaseCount = (bids: IBigScreenBid[]): number => {
        let purchaseCount = 0
        bids.forEach(bid => {
            purchaseCount += bid.count
        });
        return purchaseCount;

    }

    const determineCumulativeTotal = (bids: IBigScreenBid[]): number => {
        let cumulativeTotal = 0
        bids.forEach(bid => {
            cumulativeTotal += bid.value
        });
        return cumulativeTotal
    }



    return (
        <div className="big-screen-vertical-card-wrapper">
            <img src={props.lot.imageUrl} alt={props.lot.title} />
            <div className="card">
                <div className="lot-info-section">
                    <span className="lot-type" style={{ fontSize: `${props.bigScreenPayload.verticalLotCardFontSize * 0.5}rem`, lineHeight: `${props.bigScreenPayload.verticalLotCardFontSize * 0.5}rem` }}>
                        {props.lot.type === "COMPETITIVE" && `Competitive Bid`}
                        {props.lot.type === "SEALED" && `Sealed Bid`}
                        {props.lot.type === "CUMULATIVE" && `Pledge`}
                        {props.lot.type === "BUYITNOW" && `Buy it Now`}
                        {props.lot.type === "PARENT" && `Versions Available`}
                    </span>
                    <div className="title-wrapper">
                        <span className="title" style={{ fontSize: `${props.bigScreenPayload.verticalLotCardFontSize * 1}rem`, lineHeight: `${props.bigScreenPayload.verticalLotCardFontSize * 1}rem` }}>
                            {!props.campaign.isLotNumberHidden && `${props.lot.orderNumber}. `}
                            {props.lot.title.length > props.bigScreenPayload.verticalLotCardTitleCharacterLimit ?
                                props.lot.title.substring(0, props.bigScreenPayload.verticalLotCardTitleCharacterLimit - 3) + "..."
                                :
                                props.lot.title
                            }
                        </span>
                    </div>
                </div>
                {props.lot.type === "COMPETITIVE" &&
                    <div className="lot-specifics-wrapper">
                        <div>
                            <div className="label" style={{ fontSize: `${props.bigScreenPayload.verticalLotCardFontSize * 0.6}rem`, lineHeight: `${props.bigScreenPayload.verticalLotCardFontSize * 0.6}rem` }}>Current Winner: &nbsp;</div>
                            <div className="value" style={{ fontSize: `${props.bigScreenPayload.verticalLotCardFontSize * 0.6}rem`, lineHeight: `${props.bigScreenPayload.verticalLotCardFontSize * 0.6}rem` }}>{commonFunctions.titleCase(props.lot.winningBidderName)}</div>
                        </div>
                        <div>
                            <div className="label" style={{ fontSize: `${props.bigScreenPayload.verticalLotCardFontSize * 0.6}rem`, lineHeight: `${props.bigScreenPayload.verticalLotCardFontSize * 0.6}rem` }}>Current Bid: &nbsp;</div>
                            <div className={props.lot.bids.length === 0 ? "value reserve" : "value"} style={{ fontSize: `${props.bigScreenPayload.verticalLotCardFontSize * 0.6}rem`, lineHeight: `${props.bigScreenPayload.verticalLotCardFontSize * 0.6}rem` }}>
                                {new Intl.NumberFormat('en-US', { style: 'currency', currency: props.bigScreenPayload.currencyCode, minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(
                                    props.lot.bids.length === 0 ? props.lot.minimumBid : props.lot.winningBid
                                )}
                                {props.lot.bids.length === 0 && <span className="min-bid-text"> (Reserve)</span>}
                            </div>
                        </div>
                    </div>
                }
                {props.lot.type === "SEALED" &&
                    <div className="lot-specifics-wrapper">
                        <div>
                            <div className="label" style={{ fontSize: `${props.bigScreenPayload.verticalLotCardFontSize * 0.6}rem`, lineHeight: `${props.bigScreenPayload.verticalLotCardFontSize * 0.6}rem` }}>Minimum Bid: &nbsp;</div>
                            <div className="value" style={{ fontSize: `${props.bigScreenPayload.verticalLotCardFontSize * 0.6}rem`, lineHeight: `${props.bigScreenPayload.verticalLotCardFontSize * 0.6}rem` }}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: props.bigScreenPayload.currencyCode, minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(props.lot.minimumBid)}</div>
                        </div>
                        <div>
                            <div className="label" style={{ fontSize: `${props.bigScreenPayload.verticalLotCardFontSize * 0.6}rem`, lineHeight: `${props.bigScreenPayload.verticalLotCardFontSize * 0.6}rem` }}>Bids Received: &nbsp;</div>
                            <div className="value" style={{ fontSize: `${props.bigScreenPayload.verticalLotCardFontSize * 0.6}rem`, lineHeight: `${props.bigScreenPayload.verticalLotCardFontSize * 0.6}rem` }}>{props.lot.bids.length}</div>
                        </div>
                    </div>
                }
                {props.lot.type === "CUMULATIVE" &&
                    <div className="lot-specifics-wrapper">
                        <div>
                            <div className="label" style={{ fontSize: `${props.bigScreenPayload.verticalLotCardFontSize * 0.6}rem`, lineHeight: `${props.bigScreenPayload.verticalLotCardFontSize * 0.6}rem` }}>Total Raised: &nbsp;</div>
                            <div className={props.lot.bids.length > 0 ? "value" : "value reserve"} style={{ fontSize: `${props.bigScreenPayload.verticalLotCardFontSize * 0.6}rem`, lineHeight: `${props.bigScreenPayload.verticalLotCardFontSize * 0.6}rem` }}>
                                {new Intl.NumberFormat('en-US', { style: 'currency', currency: props.bigScreenPayload.currencyCode, minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(determineCumulativeTotal(props.lot.bids))}
                            </div>
                        </div>
                    </div>
                }
                {props.lot.type === "BUYITNOW" &&
                    <div className="lot-specifics-wrapper">
                        <div>
                            <div className="label" style={{ fontSize: `${props.bigScreenPayload.verticalLotCardFontSize * 0.6}rem`, lineHeight: `${props.bigScreenPayload.verticalLotCardFontSize * 0.6}rem` }}>Cost: &nbsp;</div>
                            <div className="value" style={{ fontSize: `${props.bigScreenPayload.verticalLotCardFontSize * 0.6}rem`, lineHeight: `${props.bigScreenPayload.verticalLotCardFontSize * 0.6}rem` }}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: props.bigScreenPayload.currencyCode, minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(props.lot.minimumBid)}</div>
                        </div>
                        <div>
                            {props.lot.isShowAvailability &&
                                <>
                                    <div className="label" style={{ fontSize: `${props.bigScreenPayload.verticalLotCardFontSize * 0.6}rem`, lineHeight: `${props.bigScreenPayload.verticalLotCardFontSize * 0.6}rem` }}>Available: &nbsp;</div>
                                    <div className="value" style={{ fontSize: `${props.bigScreenPayload.verticalLotCardFontSize * 0.6}rem`, lineHeight: `${props.bigScreenPayload.verticalLotCardFontSize * 0.6}rem` }}>
                                        {props.lot.availabilityCount - determinePurchaseCount(props.lot.bids) > 0 ?
                                            props.lot.availabilityCount - determinePurchaseCount(props.lot.bids) :
                                            <span>SOLD OUT</span>
                                        }
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default BigScreenVerticalCard