import React, { useState } from "react"
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, Select, MenuItem, FormHelperText } from "@mui/material"
import useCustomBidderDetailValidation from "./useCustomBidderDetailValidation";
import '../../dialog.scss'
import ICampaign from "../../../../interfaces/campaign";
import ICustomBidderDetail from "../../../../interfaces/customBidderDetail";

interface InputProps {
    isDialogOpen: boolean,
    handleClose: () => void,
    setCampaign: (campaign: ICampaign) => void,
    customBidderDetail: ICustomBidderDetail,
    campaign: ICampaign
}

const CustomBidderDetailDialog = (props: InputProps) => {

    const [customBidderDetail, setCustomBidderDetail] = useState<ICustomBidderDetail>({ ...props.customBidderDetail });

    const cbdValidation = useCustomBidderDetailValidation(customBidderDetail)

    const handleChange = (event) => {
        const { name, value } = event.target
        setCustomBidderDetail({ ...customBidderDetail, [name]: value })
    }

    const saveCustomBidderDetail = () => {
        if (cbdValidation.validateInputs()) {
            let tempCustomBidderDetailObject: ICustomBidderDetail[] = [...props.campaign.customBidDetailList]

            if (props.customBidderDetail.detailId) {
                tempCustomBidderDetailObject.forEach((element, index) => {
                    if (customBidderDetail.detailId === element.detailId) {
                        tempCustomBidderDetailObject[index] = customBidderDetail
                    }
                })

                props.setCampaign({ ...props.campaign, customBidDetailList: tempCustomBidderDetailObject })
                setCustomBidderDetail({} as ICustomBidderDetail)
                props.handleClose()

            } else {
                tempCustomBidderDetailObject.push(customBidderDetail)
                props.setCampaign({ ...props.campaign, customBidDetailList: tempCustomBidderDetailObject })
                setCustomBidderDetail({} as ICustomBidderDetail)
                props.handleClose()
            }
        }

    }

    React.useEffect(() => {
        if (props.customBidderDetail.detailId) {
            setCustomBidderDetail({ ...props.customBidderDetail })
        } else {
            setCustomBidderDetail({
                detailId: String(new Date().getTime()),
                label: "",
                value: "",
                placeholder: "",
                isRequired: false,
                isTableNumber: false,
                type: "",
                optionList: ""
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, [props.customBidderDetail]);

    return (
        <>
            <Dialog
                open={props.isDialogOpen}
                onClose={props.handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">Custom Bid Field</DialogTitle>
                <DialogContent>
                    {/* <p>props.cbs = {JSON.stringify(props.customBidderDetail)}</p>
                    <p>cbs = {JSON.stringify(customBidderDetail)}</p> */}
                    <TextField
                        variant="outlined"
                        id="label"
                        name="label"
                        label="Label"
                        type="text"
                        fullWidth
                        value={customBidderDetail.label}
                        onChange={handleChange}
                        required
                        helperText={!cbdValidation.getValidation("label").isValid && cbdValidation.getValidation("label").validationMessage}
                        error={!cbdValidation.getValidation("label").isValid}
                    />
                    <FormControl
                        variant="outlined"
                        fullWidth
                        required
                        error={!cbdValidation.getValidation("isRequired").isValid}
                    >
                        <InputLabel id="is-mandatory-label">Mandatory?</InputLabel>
                        <Select
                            labelId="is-mandatory-label"
                            id="isRequired"
                            name="isRequired"
                            value={customBidderDetail.isRequired}
                            onChange={handleChange}
                            label="Mandatory?"
                        >
                            <MenuItem value={true as any}>True</MenuItem>
                            <MenuItem value={false as any}>False</MenuItem>

                        </Select>
                        {!cbdValidation.getValidation("isRequired").isValid && <FormHelperText>{cbdValidation.getValidation("isRequired").validationMessage} </FormHelperText>}
                    </FormControl>
                    <FormControl
                        variant="outlined"
                        fullWidth
                        required
                        error={!cbdValidation.getValidation("type").isValid}
                    >
                        <InputLabel id="detail-type-label">Detail Type</InputLabel>
                        <Select
                            labelId="detail-type-label"
                            id="type"
                            name="type"
                            value={customBidderDetail.type}
                            onChange={handleChange}
                            label="Detail Type"
                        >
                            <MenuItem value={"INPUT"}>Text Input</MenuItem>
                            <MenuItem value={"SELECT"}>Dropdown List</MenuItem>
                        </Select>
                        {!cbdValidation.getValidation("type").isValid && <FormHelperText>{cbdValidation.getValidation("type").validationMessage} </FormHelperText>}
                    </FormControl>

                    <TextField
                        variant="outlined"
                        id="placeholder"
                        name="placeholder"
                        label="Helper Text"
                        type="text"
                        fullWidth
                        value={customBidderDetail.placeholder}
                        onChange={handleChange}
                    />


                    {customBidderDetail.type === "SELECT" &&
                        <TextField
                            variant="outlined"
                            id="optionList"
                            name="optionList"
                            label="Comma Separated Options"
                            type="text"
                            fullWidth
                            required
                            value={customBidderDetail.optionList}
                            onChange={handleChange}
                            helperText={!cbdValidation.getValidation("optionList").isValid && cbdValidation.getValidation("optionList").validationMessage}
                            error={!cbdValidation.getValidation("optionList").isValid}
                        />
                    }

                </DialogContent>
                <DialogActions>
                    <div className="action-section">

                    <div className="save-button-wrapper">
                        {!cbdValidation.isValidationPassed && <span className="validation-text">Errors highlighted in form - please resolve before saving.</span>}
                        <div className="buttons">
                            <Button id="cancel-button" onClick={props.handleClose}>
                                Cancel
                            </Button>
                            <Button id="save-button" onClick={saveCustomBidderDetail} className="gg-button">
                                Save
                            </Button>
                        </div>
                    </div>
                    </div>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default CustomBidderDetailDialog